<template>
  <article class="search_item">
    <router-link class="link_wrapper" :to="`/profile/${item.id}`">
        <a class="article_pic mbl_radius">
            <div v-if="item.pic_parsed" class="search_pic" :style="bg(item.pic_parsed)"></div>
        </a>
        <div class="content">
            <a><h3 v-html="item.display_name"></h3></a>
            <div class="date" v-html="item.num_videos + ' ' + get_word('videos')"></div>
        </div>
    </router-link>
  </article>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .search_item {
            &{display: flex;gap: 10px;color: var(--clr_main);}
            .link_wrapper{display: flex;gap: 10px;width: 100%;}
            .search_pic{height: 100%;width: 100%; background-size: cover;background-position: center;border-radius: 100%;}
            .article_pic { display: block; width: 60px; height: 60px}
            .content {
                &{position: relative; z-index: 2;flex: 1;width: 60%;display: flex;justify-content: center;flex-direction: column;border-bottom: 1px solid var(--clr_border);}
                .writer_wrapper{
                    &{display: flex;gap: 10px;margin-top: 8px;align-items: center;}
                }
            }
            h3 { color:var(--clr_main); font-size: 20px;}
            .date{font-size: 18px;color:var(--clr_date);}
    }
</style>