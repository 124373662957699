<template>
    <div id="faq_form" class="centered">
        <h1 class="gradient_txt" v-html="get_word('waiting_for_questions')"></h1>
        <p v-html="get_word('faq_form_subtitle')"></p>
        <form @submit.prevent="onSubmitQuestion">
            <div class="form_group question_title">
                <label for="question_title" v-html="get_word('_TITLE_')"></label>
                <input v-model="title" class="input_hollow" id="question_title" type="text">
            </div>
            <div class="form_group name">
                <label for="name" v-html="get_word('_NAME_')"></label>
                <input v-model="name" class="input_hollow" id="name" type="text">
            </div>
            <div class="form_group email">
                <label for="email" v-html="get_word('_EMAIL_')"></label>
                <input v-model="email" class="input_hollow" id="email" type="text">
            </div>
            <div class="form_group phone">
                <label for="phone" v-html="get_word('phone')"></label>
                <input v-model="phone" class="input_hollow" id="phone" type="text">
            </div>
            <div class="form_group question">
                <label for="question" v-html="get_word('question')"></label>
                <textarea v-model="question" class="input_hollow" name="question" id="question" cols="30" rows="10"></textarea>
            </div>
            <button class="btn primary" v-html="get_word('faq_form_submit')"></button>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            name: '',
            email: '',
            phone: '',
            question: '',
        }
    },
    methods:{
        onSubmitQuestion() {
            if(this.title == '' || this.name == '' || this.question == '') {
                this.$swal({
                title: this.get_word('insert_content'),
                confirmButtonColor: '#B0B7ED',
                confirmButtonText: this.get_word('confirm'),
                });
                return false;
            }
            
            this.api({ action: 'faq/save_question', data: { name:this.name, email: this.email, phone: this.phone, question: this.question, title: this.title }}, () => {
                this.title = '';
                this.name = '';
                this.email = '';
                this.phone = '';
                this.question = '';
                this.$swal({
                title: this.get_word('form_success'),
                confirmButtonColor: '#B0B7ED',
                confirmButtonText: this.get_word('confirm'),
                });
                
            });
            
        },
    }
}
</script>

<style lang="scss">
    #faq_form {
        &{margin-bottom: 40px;padding-top:15px;}
        &>h1, &>p{text-align: center;}
        h1{
            @media(min-width: 600px){font-size: 40px;line-height: 1.2;}
            @media(max-width: 600px){line-height: 1;margin-bottom: 10px;}
        }
        p{font-size: 17px;margin-bottom: 30px;}
        
        form{
            @media(min-width: 600px){display: grid;grid-template-columns: repeat(3, 1fr);width: 70%;margin: 0 auto;column-gap: 20px;row-gap: 25px;}
            @media(max-width: 600px){display: flex;flex-direction: column;gap: 20px;}
            .form_group {
                &{display: flex;flex-direction: column;}
                input{width: 100%;padding: 16px;}
                textarea{width: 100%;padding: 16px;}
                &.question, &.question_title{grid-column: 1/-1;}
            }
            .btn_primary{
                &{grid-column: 1/-1;height: 60px;font-weight: 700;letter-spacing: 0.25em;font-size: 23px;border: none;line-height: 1;}
                // &::after{content: "←";margin: 0 5px;}
            }
            
        }
    }
</style>