<template>
    <div id="success_page" class="centered">
        <h1 v-html="get_word('success_page_title')"></h1>
        <!-- <h3 v-html="get_word('confirmation_id')"></h3> -->
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>