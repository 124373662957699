import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import globalMixin from './globalMixin'
import $bus from './eventbus';

import './css/general.scss'
import './css/style.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


import Banner from './components/banner.vue'

window.api_url = 'https://www.torahvoice.org/api_vue/'; 
window.site_url = 'https://www.torahvoice.org/'; 

const app = createApp({
    extends: App
}).use(store).use(router);

app.config.globalProperties.$bus = $bus;

app.mixin(globalMixin);
app.component('Banner', Banner);

import axios from 'axios'
import VueAxios from 'vue-axios'
app.use(VueAxios, axios)

   import VueSweetalert2 from 'vue-sweetalert2';
   import 'sweetalert2/dist/sweetalert2.min.css';
  
  app.use(VueSweetalert2);
  
  import VueVideoPlayer from '@videojs-player/vue'
  require('videojs-youtube');
  import 'video.js/dist/video-js.css'
  app.use(VueVideoPlayer)
  

app.mount('#app');
