<template>
  <div id="answer_page">
    
    <div class="centered">
        <div class="hp_grid">
            <div class="main_side">
                
                <div class="head_content">
               
                    <h1 class="page_title no_border">{{(question.seo_h1 != "") ? question.seo_h1 : question.title}}</h1>
                    <h2 v-if="question.seo_h2" class="short_text" v-html="question.seo_h2"></h2>
                                
                    <ul class="writer_line">
                        <li>
                            <i class="fa-regular fa-gradient fa-calendar-days"></i><span v-html="question.date_heb"></span>

                            <time itemprop="datePublished" :datetime="question.date_parsed">
                                <span v-html="question.time_posted"></span>
                            </time>

                        </li>
                    </ul>
                </div>
                
                <div id="article_content">
                    <!-- <div id="article_soci_bar">
                        <div class="font_size_btn"><span class="font_size_small_btn">א</span><span class="font_size_big_btn">א</span></div>
                        <div class="soci_inner">
                            <a class="bg_facebook" :href="(section.fb_link != '') ? section.fb_link : 'https://www.facebook.com'" target="_blank" ><i class="fab fa-facebook"></i><span v-html="get_word('share_facebook')"></span></a>
                            <a class="soci_email fancy bg_email" :href="`actions/share_article/${question.id}`"><i class="fas fa-envelope"></i><span v-html="get_word('share_email')"></span></a>
                            <a class="soci_print bg_print" href="javascript:void(0);" title="הדפסה" onClick="window.print();"><i class="fas fa-print"></i><span v-html="get_word('print')"></span></a>
                            <a class="bg_whatsapp" title="whatsapp" aria-label="whatsapp" :href="`whatsapp://send?text=${question.title} ${question.link}`"><i class="fab fa-whatsapp"></i><span v-html="get_word('share_whatsapp')"></span></a>
                        </div>
                        
                    </div> -->
                    
                    <div id="article_inner_wrap">
                        <div id="article_inner">
                            <h3 class="section_title"><span v-html="get_word('question')"></span></h3>
                            <p class="answer" v-html="question.question"></p>
                            <h3 class="section_title"><span v-html="get_word('answer')"></span></h3>
							<p class="answer" v-html="question.answer"></p>
                        </div>

                        <div id="articles_nav">
                       
                            <a v-if="prev_article" class="prev" :href="prev_article.link">
                                <i class="fa-solid fa-chevron-right"></i>
                                <div class="pic" :style="bg(prev_article.pic_parsed)"></div>
                                <div>
                                    <h4>אל תפספסו</h4>
                                    <h3 v-html="prev_article.title"></h3>
                                </div>
                            </a>
                           
                            <a v-if="next_article" class="next" :href="next_article.link">
                                <i class="fa-solid fa-chevron-left"></i>
                                <div class="pic" :style="bg(next_article.pic_parsed)"></div>
                                <div>
                                    <h4>השאלה הבאה</h4>
                                    <h3 v-html="next_article.title"></h3>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
              
                

<!--
                <div id="article_tags" class="label_tabs gray_bg">
                    <span>תגיות:</span>
                    {foreach $tags_arr as $tag}<a href="search?q={$tag|escape:'url'}">{$tag}</a>{/foreach}
                </div>
-->
                
<!--                <div class="crm_comments hide_in_print" data-id="{$data.id}" data-type="article"></div>-->
                
            <!-- </div>
       {*     <div class="left_side">
                {m_banner::get_desktop('bar_square')}
                {m_banner::get_desktop('bar_square')}
                <div>{$more_articles_block}</div>
                {$widgets->external_links()}
                <div>{$widgets->shops()}</div>
                
            </div>*} -->
        </div>
    </div>
    </div>
    
</div>
</template>

<script>
export default {
    data() {
        return {
            question: {},
            section: {},
            prev_article: null,
            next_article: null,
        }
    },
    mounted(){
        if (!this.$route.params.id) this.$router.push('/faq');
        this.api({ action: 'faq/get', data: {id:this.$route.params.id}}, (data)=>{
          if(data.data) this.question = data.data;
          else this.$router.push('/faq');
        });
    }
}
</script>

<style lang="scss">
#answer_page {
	#breadcrumbs{
		li {
			a{cursor: pointer;}
			a:hover{
				span{color: var(--color_link);}
			}
			&.last{
				&{cursor: default;}
				&, &:hover span{color: var(--color_link);}
			}
		}
	}
	
	.page_title{line-height: 1;margin-bottom: 10px;font-size: 35px;}
	
	ul.writer_line{
		&{margin-bottom: 30px;}
		li {
			&{display: flex;gap: 10px;align-items: center;}
			&>span::after{content: "|"; margin-inline-start: 10px;}
		}
	}
	
	#article_content {
		&{display: flex;}
		@media(min-width: 600px){}
		@media(max-width: 600px){flex-direction: column;}
		#article_soci_bar{
			&{position: relative;}
			@media(min-width: 600px){width: 100px;}
			@media(max-width: 600px){display: flex;gap: 10px;align-items: center;}
			.font_size_btn{
				&{font-weight: bold;letter-spacing: 4px;margin-bottom: 20px;color: #8395a7;cursor: pointer;width: fit-content;}
				.font_size_small_btn{font-size: 17px}
				.font_size_big_btn{font-size: 21px}
			}
			.soci_inner{
				&{position: sticky;top: 70px;z-index: 5;}
				@media(min-width: 600px){width: 95px;}
				@media(max-width: 600px){display:flex;gap: 10px;}
				a{
					&{display: flex;width: 45px;height: 45px;justify-content: center;border-radius: 45px;align-items: center;font-size: 19px;color: #fff;margin-bottom: 15px;-moz-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;-webkit-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
					&:hover{
						&{width: 140px;filter: saturate(1.5) brightness(1.1);}
						span{width: auto;margin-inline-start: 5px;}
					}
					&.bg_facebook {background: #3b5998;}
					&.bg_email {background: #ea4335;}
					&.bg_print {background: #aaa;}
					&.bg_whatsapp {background: #25d366;}
					
					span{width: 0;overflow: hidden;white-space: nowrap;font-size: 15px;}
				}
			}
		}
		
		#article_inner_wrap {
				&{max-width: 700px;}
				.section_title{
					&{text-align: initial;font-size:32px;font-weight: 600; background: linear-gradient(81.62deg, #3B5EC2 -17.79%, #7FFFC0 65.8%, #F9F871 141.11%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}

					&.menu_title_1{margin-top: 40px;}
				}
				p{width: 96%;margin: auto;font-size: 20px;}
		}
		
	}
}
</style>