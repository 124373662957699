<template>
  <div id="page" :class="'rtl ' + (show_side_menu ? 'menu_open' : '') + ' ' + (user && user.is_subscribed ? 'is_vip' : '')  + $store.state.playerMode">

    <search v-if="is_search_opened" @closesearch="is_search_opened = false" />
    <div ref="sideMenu" id="side_menu">

      <!-- <div id="menu_logo"></div> -->
        
      <ul>
        <!-- <li><a id="search_btn" @click="$bus.$emit('open_search'); show_side_menu=false">חיפוש באתר <i class="fas fa-search"></i></a></li> -->
        <!-- <li>
          <div class="theme-switch-wrapper">
            <span v-html="get_word('dark_mode')"></span>
            <label class="theme-switch" for="checkbox">
                <input v-model="isDarkTheme" @change="changeTheme" type="checkbox" id="checkbox" />
                <div class="slider round"></div>
            </label>
          </div>
        </li> -->
        <li v-for="menu_item in menu" :key="menu_item.id">
          
          <router-link @click="closeMenu" :to="menu_item.link">
            <div class="inner_wrapper">
            <div v-if="menu_item.icon" class="icon" v-html="menu_item.icon"></div>
            <span>{{menu_item.name}}</span>
            </div>
            <i class="fas fa-chevron-left"></i>
          </router-link>

        </li>
        <li v-if="get_me()">
          <a @click="logout()" v-html="get_word('_LOGOUT_')"></a>

        </li>
      </ul>


    </div>

    <main id="page" v-if="loaded"> 

      <!-- <div id="menu_hamburger" v-on:click="show_side_menu=!show_side_menu">
        <div id="nav-icon3"><span></span><span></span><span></span><span></span></div>
      </div> -->

      <Header :class="header_class" />
      <div id="content" :class="{second_page_active: isSecondPageActive} ">
   
        <div id="first_page">
          <!-- <pull-to-refresh @refresh="$bus.$emit('refresh')"> -->
            <router-view v-slot="{ Component }" @loaded="is_show_loader=false;" @show_loader="is_show_loader=true;" @setLang="reloadPage">
              <transition name="fade">
                <component :is="Component" :key="$route.path" />
              </transition>
            </router-view>
          <!-- </pull-to-refresh> -->
        </div>
        <div id="second_page">
          <component :is="secondPageView" v-bind="secondPageParams" />
        </div>

      </div>

      <Footer @toggleMenu="handleMenuToggle" v-if="$route.matched[0].props.default.footer !== false" :footer_menu="footer_menu" :foot_line="foot_line" />

      <Item v-if="item" :m_item="item" :m_id="item.id" />

    </main>
    
    <div id="loading_animation" v-if="is_show_loader || !loaded"><div id="spinner_ajax"></div></div>
  </div>
</template>

<script>

import Header from './components/global/header.vue'
import Footer from './components/global/footer.vue'
import search from './components/global/search.vue'
import Comments from './views/comments.vue'
import ask from './views/faq/ask.vue'
import questions from './views/faq/questions.vue'
import faq from './views/faq/index.vue'

import Post from './views/item.vue'
import category from './views/category.vue'
import Item from './views/item.vue'
// import PullToRefresh from './components/global/pullToRefresh.vue'



export default {
  name: 'app',
  components: { Header, Footer, search, Comment, Item },
  data(){
    return {
      loaded: false,
      menu: [],
      banner:null,
      header_class: '',
      footer_menu: {},
      foot_line: {},
      item: null,
      show_side_menu: false,
      show_splash_screen: false,
      is_show_loader: false,
      is_search_opened: false,
      show_banner_screen: true,
      pageViewsCounter: 0,
      user: false,
      isSecondPageActive: false,
      secondPageView: false,
      secondPageParams: false,
      isDarkTheme: false
    }
    
  },
  mounted() { 
    window.appVue = this;
    this.$bus.$on('reload', (loadData, openURL) => { this.reload(loadData, openURL); });
    this.$bus.$on('open_search', () => { this.is_search_opened=true; });
    this.reloadPage();

    this.$bus.$on('close_menu', this.closeMenu);
    this.$bus.$on('close_menu', this.changeTheme);
    this.$bus.$on('open_item', (item) => {
      this.item = item;
      
    });
    this.$bus.$on('close_item', () => {
      this.item = null;
    });

    this.$bus.$on('second_page', (settings) => { 
    
      this.isSecondPageActive=true; 
      switch(settings.type)
      {

        case 'ask': { this.secondPageView = ask; }
        break;
        case 'all_questions': { this.secondPageView = questions; }
        break;
        case 'faq': { this.secondPageView = faq; }
        break;
        case 'comments': { this.secondPageView = Comments; this.secondPageParams = {item_id: settings.id, item_type: 'video', item: settings.item};}
        break;
        case 'item': { this.secondPageView = Post; this.secondPageParams = {m_id: settings.id, m_item: settings.item}; }
        break;
        case 'category': { this.secondPageView = category; this.secondPageParams = {category: settings.category}; }

      }

      window.location.hash = '#second_page_hash';

      window.onpopstate = (/*event*/) => {
        if (window.location.hash == '') this.isSecondPageActive=false;
      };

    });


    const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;

    if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);

        this.isDarkTheme = currentTheme === 'dark';

    }

  },
  methods:{
    
    reload(loadData, openURL){
      this.loaded=false;

      if (this.isNull(openURL)) openURL = '/';

      if (this.$route.path !== '/') this.$router.push(openURL);
      //this.refreshKey++;
      if (loadData !== false) this.reloadPage();
      else this.loaded = true;
    },
    closeMenu(){
      this.show_side_menu = false;
    },
    handleMenuToggle() {
      this.show_side_menu = !this.show_side_menu;
    },

    reloadPage(cb = null){

      let getVars = {};
      let uri = window.location.href.split('?');
      if(uri.length == 2) {
        let vars = uri[1].split('&');
        
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });
        // do 
      }

      if(!this.isNull(getVars.token)) {
        this.set_user_token(getVars.token);
        document.location = '/'
      }

      const platformId = +localStorage.getItem('langId') || 1;
      if (platformId && platformId != 1) document.body.classList.add('ltr');
      else document.body.classList.remove('ltr');
      // categories
      this.api({ action: 'general', method: 'post' ,data:{platformId}}, (data) => { 
        
        this.menu = data.data.menu;
        
        this.footer_menu = data.data.footer_menu;
        //this.foot_line = data.data.foot_line;
        this.$store.state.words = data.data.words;
        this.$store.unit_types = data.data.unit_types;
        //this.$store.footer_articles = data.data.footer_articles;

        this.$store.user = data.data.user;
        this.user = data.data.user;

        this.loaded = true;
        // self.show_splash_screen = false;
        if(cb && typeof cb === 'function') cb();
      });
    }
  },
  watch:{
    '$route' (to/*, from*/){
      //if (window.location.hash != '') 
      if (to.hash == '') 
      {
        window.location.hash = '';
        this.secondPageView=false;
        this.secondPageParams=false;
        this.isSecondPageActive=false;
        if(!this.isNull(this.item)) this.$bus.$emit('minimize_item_page');

        
      }
      //
    }
  },

}
</script>

<style lang="scss">


#content { display: flex; width:200vw;  transform: translateX(0vw);  transition: all 0.2s ease 0s ; 
  &.second_page_active { transform: translateX(100vw); }
}

#first_page,#second_page { width: 100vw; overflow-y: auto; height: calc(100vh - var(--header_height) - 60px); position: relative;}

#page {
  &{background-color: var(--clr_main2);color: var(--clr_main);}

  #content{
    ::-webkit-scrollbar{display: none;}
  }

  &.player_minimized {
    #first_page,#second_page {height: calc(100vh - var(--header_height) - 60px - var(--player_mini_height));}
  }
}

</style>