<template>
	<footer id="main_footer">
   <router-link  @click="$bus.$emit('close_menu')" to="/login" v-if="!get_me()">
        <i class="far fa-user"></i>
   </router-link>
     <router-link  @click="$bus.$emit('close_menu')" to="/profile/me" id="user_side" v-if="get_me()" >
      <UserIcon :user="get_me()" size="sml" />
      <!-- <span v-text="get_me().display_name" style="color:#fff" class="show_in_desktop"></span> -->
    </router-link>
     <router-link  @click="$bus.$emit('close_menu')" to="/magazine">
      <i class="far fa-compass"></i>
     </router-link>
     <button @click="$emit('toggleMenu')" class="clean_btn" id="btn_footer_menu">
      <div class="small_logo"></div>
     </button>
     <router-link @click="$bus.$emit('close_menu')" to="/search">
        <i class="fas fa-search"></i>
     </router-link>
     <router-link  @click="$bus.$emit('close_menu')" to="/">
        <i class="fas fa-home"></i>
     </router-link>
  </footer>
</template>

<script>
import UserIcon from '../user_icon.vue'
export default {
  components: { UserIcon },
   data() {
      return {
         isMenuOpen: false,
      }
   },
}
</script>

<style lang="scss">
  #main_footer {
      &{height: 61px;background-color: var(--clr_main2);display: flex;align-items: center;position: fixed;bottom: -1px;left: 0;right: 0;z-index: 9999999;border-top: 1px solid #ccc;}

      a {
        &{color: var(--clr_main);flex: 1;text-align: center;font-size: 24px;}
      }

      #user_side{display: flex;justify-content: center;}

      #btn_footer_menu{
         &{width: 64px;height: 64px;box-shadow:0 0 11px 2px rgba(0,0,0, 0.14);padding: 10px;border-radius: 100%;margin-top: -3rem;transition: all .25s;background-color: #fefefe;}

         &:active{transform: scale(0.8);}

         .small_logo{width: 100%;height: 100%;}
      }
  }
</style>