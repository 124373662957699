<template>
  <div id="contact_page">
    <div class="top_wrapper">
      <h1 v-html="get_word('contact_us')"></h1>
      <span v-html="get_word('contact_sub1')"></span>
      <h3 v-html="get_word('contact_sub2')"></h3>
    </div>

    <section id="form_wrapper">
      <div id="dvAjax_signupFRM" class="frm">
        <form
          name="signupFRM"
          id="signupFRM"
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="send"
        >
          <ul class="frmUL">
            <li class="main_input">
              <label v-html="get_word('_NAME_')"></label>
              <span class="field">
                <input v-model="form.name" required type="text" name="name" />
              </span>
            </li>
            <li class="main_input">
              <label  v-html="get_word('email')"></label>
              <span class="field">
                <input v-model="form.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$" type="text" name="email"/> 
            </span>
            </li>
            <li class="main_input">
              <label  v-html="get_word('phone')"
                > </label
              ><span class="field"
                ><input required type="text" name="phone" v-model="form.phone" /> </span
              >
            </li>
            <li class="main_input">
              <label  v-html="get_word('subject')"></label>
              <span class="field">
                <input v-model="form.subject" required type="text" name="subject"/> 
                </span>
                
            </li>
            <li class="msg_input">
              <label  v-html="get_word('msg_content')"></label
              ><span class="field">
                <textarea v-model="form.msg" cols="30" rows="5" name="msg"></textarea
                ><span class="charsRemaining"></span></span
              >
            </li>
            <li class="sbm_area">
              <input
                class="btn_primary clean_btn"
                type="submit"
                :value="get_word('send_form')"
                name="btnSubmit_signupFRM"
                id="btnSubmit_signupFRM"
              />
            </li>
          </ul>
          <input type="hidden" value="1" name="isFormSent_signupFRM" />
        </form>
      </div>

      <!-- <a class="contact_whatsapp" href="" target="_blank">
        <div class="icon bg_img"></div>
        <span v-html="get_word('contact_whatsapp')"></span>
      </a> -->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: false,
      form: {
        msg: '',
        name: '',
        phone: '',
        email: '',
        subject: '',
      },
    }
  },
  methods: {
    send() {
      this.api({ action: 'contact', data: this.form }, (data) => {
            if (data.data === 'error') {
                return this.alert(this.get_word('server_error'), null, false, 'error');
            } 
            this.alert(this.get_word('form_success'), null, false, 'success');
            this.form.msg = '';
            this.form.name = '';
            this.form.phone = '';
            this.form.email = '';
            this.form.subject = '';
      })
    },
  },
}
</script>

<style lang="scss">
#contact_page {
    & {padding: 20px;}
    #form_wrapper {
            form {
                ul.frmUL {
                    @media(min-width: 600px){display: grid;grid-template-columns: repeat(2, 1fr);}
                    @media(max-width: 600px){display: flex;flex-direction: column;}
                    li.main_input {
                        &{display: flex;flex-direction: column;margin-bottom: 0;}
                        input{width: 100%;border: solid 1px var(--clr_contact);padding: 16px;color:var(--clr_main);background-color: transparent;border-radius: 5px;padding: 16px;min-height: 48px;}
                        label{color: var(--clr_main);}
                    }
                    
                    li.msg_input{
                        &{grid-column: 1/-1;margin-bottom: 0;}
                        textarea{width: 100%;padding: 16px;color:var(--clr_main);background-color: transparent;border-radius: 5px; padding: 16px;min-height: 148px;border: solid 1px var(--clr_contact);}
                    }
                    
                    
                    
                    .sbm_area{
                        &{grid-column: 1/-1;height: 60px;font-weight: 700;letter-spacing: 0.25em;font-size: 23px;}
                        .btn_primary{
                            &{width: 100%;height: 60px;font-weight: 700;letter-spacing: 0.25em;font-size: 23px;background: var(--gradient1);border-radius: 5px;color: var(--color2);padding: 5px 1em;font-weight: bold; cursor: pointer;transition: all .2s;text-align: center;justify-content: center;}
                            &::after{content: "←";margin: 0 5px;}
                            &:hover{color: var(--color);}
                        }
                        
                    }
                }
            }
        
            .contact_whatsapp{
                @media(min-width: 600px){}
                @media(max-width: 600px){display: none;}
            }
    }
}
</style>
