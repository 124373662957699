<template>
    <div :class="'writer_pic ' + size + ' ' + (!user.pic ? 'nopic' : '')" :style="bg(pic)"></div>
</template>

<script>

export default {
    
    props: ["user", "size"],
    computed: {
        pic(){
            if (this.isNull(this.user.pic_tumb_sml)) return false;
            else return this.user.pic_tumb_sml;
        }
    }
}
</script>

<style lang="scss">
.writer_pic{ background-size: cover;background-repeat: no-repeat;background-position: center;border-radius: 100%;
    &.sml { width:32px; height: 32px; }
    &.mid { width:72px; height: 72px; }
    &.big { width: 90px;height: 90px; }
    &.nopic { background-image: url(@/assets/images/nouser.jpg); }
}

</style>