<template>
  <div v-if="loaded" id="category_page">
    <h1 v-html="pageTitle"></h1>
    <div class="items_list" v-if="items && items.length > 0">
        <ListItem @click="$bus.$emit('open_item', item)" v-for="item in items" :item="item" :key="item.id"/>
        <!-- <article v-for="item in items" :key="item.id" :class="'item_type_' + item.type">
            <div class="content">
                <router-link :to="item.app_link" @click="close_search()"><h3 v-html="item.title"></h3></router-link>

                <div v-if="item.lecturer" class="writer_wrapper">
                    <UserIcon :user="item.lecturer" size="sml"/>
                    <span class="writer" v-if="item.lecturer" >
                            {{item.lecturer.display_name}}
                    </span>
                </div>
                <div class="date" v-else v-html="item.date_parse"></div>

            </div>
            
                <router-link :to="item.app_link" @click="close_search()" class="article_pic mbl_radius" :class="{play: item.type == 'video'}">
                <div class="search_pic" :style="bg(item.pic_tumb)"></div>
            </router-link>

        </article> -->
    </div>
    <h3 v-else v-html="get_word('no_videos')"></h3>
  </div>
  <div v-else class="loading" ><div class="lds-dual-ring"></div></div>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
export default {
  components: { ListItem },
    props:{
        category: {
            required: true,
            type: Object
        },
    },
    data() {
        return {
            items: [],
            loaded: false
        }
    },
    computed:{
        pageTitle(){
            return this.get_word('category_page_title').replace('XXX', this.category.name);
        }
    },
    mounted(){
        if (this.category) {
            this.api({ action: 'vod/results', data:{category:this.category.id}}, (data)=>{
                this.items = data.data.items;
                this.loaded = true;
            })
        }
        else this.$router.back();
    }
}
</script>

<style lang="scss">
    #category_page {
        &{padding: 0 15px; }
        h1{margin-bottom: 20px;}
        .article_pic { display: block; width: 100px; height: 90px; margin-bottom: 8px; }
        
        .writer_wrapper{
            &{display: flex;gap: 10px;margin-top: 8px;align-items: center;}
        }

        .items_list {
            &{display: flex;flex-direction: column;gap: 15px;}
            article{
                &{display: flex;gap: 10px;color: #656565;}
                .content{flex-basis: 80%;}
                .search_pic{height: 100%;width: 100px; background-size: cover;background-position: center;}
                p { color: var(--clr_main); }
                h3 { color:var(--clr_main); }
            }
        }
    }
</style>