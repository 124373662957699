<template>
    <button class="like" @click="doAction" :key="item.user_liked">
        <i v-show="!item.user_liked" class="far fa-heart"></i>
        <i v-show="item.user_liked" class="fas fa-heart"></i>
    </button>
  </template>
  
<script>
export default {
  props: {
      modelValue: Boolean,
      item: {
          type: Object,
          required: true
      }
  },
  methods:{
    doAction()
    {
        if (!this.get_me()) {
            this.alert(this.get_word('need_to_login'));
            return;
        }

        if (this.modelValue === true) this.unlike();
        else this.like();
    },
    updateValue(val) {
        this.$emit('update:modelValue', val);
        this.$emit('likechange');
    },
    like(){
        this.api({ action: 'likes/add', data: {post_id: this.item.id } }, () => {
            
        });
        this.updateValue(true);
    },
    unlike(){
        this.api({ action: 'likes/remove', data: {post_id: this.item.id } }, () => {
            
        });
        this.updateValue(false);
    },
  }
}
</script>
  
<style lang="scss">
</style>