<template>
  <div id="bookmark_page">
    <!-- <PullRefresh v-model="loading" @refresh="onRefresh" :pulling-text="'טוען...'" :loading-text="'טוען...'" :loosing-text="'טען תכנים נוספים'" :success-text="'מציג תכנים חדשים'"> -->
      <div class="loading" v-show="on_loading"><div class="lds-dual-ring"></div></div>
      <div class="main_content" v-if="loaded">
        <h2 v-if="items.length > 0" v-html="get_word('my_bookmarks')"></h2>
        <h2 class="no_bookmarks" v-else v-html="noBookmarks"></h2>
        <Post v-for="item in items" :key="item.id" :item="item" />
      </div>

      <div id="pagetop" class="fixed right-0 bottom-0" :class="{is_shown: scrollY > 400}" @click="toTop">
          <i class="fa-solid fa-chevron-up"></i>
      </div>
      
    <!-- </PullRefresh> -->
  </div>

</template>

<script>

import Post from '@/components/feed/post.vue'
// import PullRefresh from 'pull-refresh-vue3'

export default {
  components: { Post /*, PullRefresh*/ },
  data() {
    return {
      hero: {},
      sections: [],
      loaded: false,
      loading: false,
      items: [],
      // LoadOnScrollData
      on_loading: false,
      page: 0,
      stopLoading: false,
      scrollY: 0,
      scrollTimer: 0
    };
  },
  mounted() {

    this.load();

 
    setTimeout(() => { 
      this.scroll_container_to_scroll.addEventListener('scroll', this.onScrollEvent);
      this.scroll_container_to_scroll.addEventListener('scroll', this.handleScroll);
      }, 300)
  },
  unmounted () {
    this.scroll_container_to_scroll.removeEventListener('scroll', this.onScrollEvent);
  },
  computed:{
    noBookmarks(){
      return this.get_word('no_bookmarks').replace('XXX', '<i class="fa-solid fa-bookmark"></i>')
    },
    scroll_container(){
        return document.getElementById('first_page');
    },
    scroll_container_to_scroll(){
        return document.getElementById('first_page');
    },
    scroll_margin(){
        return (this.is_mobile() ? 1200 : 800)
    },
  },
  methods: {
    onRefresh() {
      this.load();
    },
    handleScroll() {
        if (this.scrollTimer) return;
        this.scrollTimer = setTimeout(() => {
          this.scrollY = this.scroll_container_to_scroll.scrollTop;
          clearTimeout(this.scrollTimer);
          this.scrollTimer = 0;
          
        }, 100);
      },
      toTop() {
        this.scroll_container_to_scroll.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    load() {
      // this.$emit('show_loader');

      this.page++;
      
      if (this.page == 1) 
      {
          this.items = [];
      }

      if (this.stopLoading) return;
      this.on_loading = true;
      
      this.api({ action: 'feed/results', data: {page: this.page, platform_id: this.platformId(), is_bookmarks: true, all_langs: true} }, (data) => {

        this.numResults = data.data.num;

        this.stopLoading = (data.data.items.length == 0);

        if (this.page == 1) this.items = data.data.items; 
        else this.items.push(...data.data.items); 
        this.on_loading = false;

        // this.$emit('loaded');
        this.loaded = true;
        this.loading = false;
      });
    },
    
    onScrollEvent(){
      if  (!this.on_loading && this.scroll_container.scrollTop + this.scroll_margin >= (this.scroll_container.scrollHeight - this.scroll_container.offsetHeight ))
      {
        this.load();
      }
    },
  },
}

</script>

<style lang="scss" scoped>
    #bookmark_page{
      &{overflow: hidden;}
      h2{
        &{padding: 0 20px;}
        &.no_bookmarks{
          &{position: absolute;top: 30%;text-align: center;opacity: 0.9;}
        }
      }
      #pagetop{
        &{opacity: 0;pointer-events: none;transition: all .25s; background-color: var(--clr_main);color: var(--clr_main2); display: flex;align-items: center;justify-content: center;padding: 15px;border-radius: 100%;position: fixed;right: 10px;bottom: 10px;}
        &.is_shown{opacity: 1;pointer-events: all;}
      }
    }
</style>