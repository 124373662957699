<template>

    <div id="profile_page" class="paddme" v-if="loaded">
        <teleport to="#header_inner" >
            <div id="notification_wrapper">
                <!-- hollow -->
                <button v-if="user_id != 'me' " class="clean_btn" @click="toggleNotifications">
                    <template v-if="!isNotifications">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"/></svg>
                    </template>
                    <template v-if="isNotifications">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"/></svg>
                    </template>
                    
                </button>
                <!-- filled -->
            </div>
        </teleport>

       <div class="head">

            <UserIcon :user="profile" size="big" />

            <div class="stats">
                <div v-if="stats.num_posts && stats.num_posts > 0" class="stat">
                    <span v-text="stats.num_posts"></span>
                    <span v-html="get_word('posts')"></span>
                </div>
                <div v-if="stats.num_followers && stats.num_followers > 0" class="stat">
                    <span v-text="stats.num_followers"></span>
                    <span v-html="`${stats.num_followers > 1 ? get_word('followers') : get_word('follower')}`"></span>
                </div>
            </div>

       </div>

       <div class="profile_details">
            <span class="name" v-text="profile.name"></span>
            <p class="desc" v-html="profileDesc"></p>
            <button class="show_more" @click="isShowMore = !isShowMore" v-if="isLongDesc" v-html="showMoreBtnText"></button>
       </div>

       <div class="actions_wrapper" v-if="profile.type=='lecturer'">
            <Follow :item="profile" v-model="profile.user_follow" @followchange="blat" v-if="profile.id != get_me().id" />
            <routerLink to="/hevruta" class="btn" v-html="get_word('hevruta')"></routerLink>
       </div>

       <!-- <div class="tab_toggle mbl_no_margins">
            <button class="clean_btn content_tab" @click="selectedTab = 'content'" :class="{active: selectedTab === 'content'}">
                <i class="fas fa-th"></i>
            </button>
            <button class="clean_btn lectures_tab" @click="selectedTab = 'lectures'" :class="{active: selectedTab === 'lectures'}">
                <i class="fas fa-graduation-cap"></i>
            </button>
       </div> -->
       
       <div>
            <!-- <ul class="content_grid mbl_no_margins" v-if="selectedTab === 'content'">
                <li v-if="items.length < 1">
                    <h3 v-html="get_word('no_content')"></h3>
                </li>
                <li v-for="item in items" :key="item.id" @click="$bus.$emit('second_page', {type: 'item', id: item.id, item: item})">
                   <span :style="bg(item.pic_mid)" ></span>
                </li>
            </ul> -->

            <ul class="content_grid mbl_no_margins" v-if="selectedTab === 'content'">
                <li v-if="items.length < 1">
                    <h3 v-html="get_word('no_content')"></h3>
                </li>
                <li v-for="item in items" :key="item.id" @click="$bus.$emit('open_item', item)">
                   <span class="pic" :style="bg(item.pic_rectangle)" ></span>

                   <div class="content_wrapper">
                    <h3 v-html="item.title"></h3>
                    <!-- <p class="short_text" v-if="item.short_text && item.short_text !=''" v-html="item.short_text"></p> -->
                    <div v-if="item.duration_parse" class="duration_wrapper">
                        <i class="far fa-clock"></i>
                        <p class="duration"  v-html="item.duration_parse"></p>
                    </div>
                    <span class="date" v-html="item.date_parse"></span>
                   </div>
                </li>
            </ul>
            <div class="loading" v-show="on_loading"><div class="lds-dual-ring"></div></div>
        </div>

       <ul class="lesson_list" v-if="selectedTab === 'lectures'">
            <li v-if="lectures.length < 1">
                <h3 v-html="get_word('no_lectures')"></h3>
            </li>
            <li v-for="lecture in lectures" :key="lecture.id">
                <div class="content">
                    <h2 v-html="lecture.subject"></h2>
                    <span class="location" v-html="lecture.address"></span>
                    <div class="details">
                        <div class="time">
                            <i class="far fa-clock"></i>
                            <span v-html="lecture.date + ' ' + lecture.hour"></span>
                        </div>
                        <div class="phone">
                            <i class="fas fa-phone-alt"></i>
                            <a :href="'tel:' + lecture.phone" v-html="lecture.phone"></a>
                        </div>
                    </div>
                </div>

                <div class="day">
                    <h3 v-html="lecture.day"></h3>
                </div>
            </li>

       </ul>
    </div>
    
    
</template>

<script>
import Follow from '@/components/follow.vue';
import UserIcon from '@/components/user_icon.vue';

    
export default {
    
    components: { Follow,UserIcon },
    data() {
        return {
            loaded: false,
            isShowMore: false,
            selectedTab: "content",
            items: [],
            lectures: [],
            user_id: this.$route.params.id,
            user: {},
            // LoadOnScrollData
            on_loading: false,
            page: 0,
            stopLoading: false,
            stats: {},
            profile: {},
            isNotifications: false
        };
    },
    mounted() {
        this.api({ action: "user/profile", data: { user_id: this.user_id } }, (data) => {
            this.profile = data.data.profile;
            this.stats = data.data.stats;
            this.lectures = data.data.lectures;
            this.loaded = true;

            const channelNotifications = JSON.parse(localStorage.getItem('channelNotifications'));

            if (!channelNotifications) localStorage.setItem('channelNotifications', JSON.stringify([]));
            else {
                this.isNotifications = channelNotifications.some((noti)=>+noti === +data.data.profile.id);
            }
        });
        this.load_results();
        setTimeout(() => {
            this.scroll_container_to_scroll.addEventListener("scroll", this.onScrollEvent);
        }, 300);
    },
    unmounted() {
        this.scroll_container_to_scroll.removeEventListener("scroll", this.onScrollEvent);
    },
    computed: {
        notiClass(){
            console.log(this.isNotifications);
            return this.isNotifications ? 'fas' : 'far';
        },
        scroll_container() {
            return document.getElementById("first_page");
        },
        scroll_container_to_scroll() {
            return document.getElementById("first_page");
        },
        scroll_margin() {
            return (this.is_mobile() ? 1200 : 400);
        },
        isLongDesc() {
            if (this.isNull(this.profile.about_content))
                return "";
            return this.profile.about_content.length > 200;
        },
        profileDesc() {
            if (this.isNull(this.profile.about_content))
                return "";
            if (!this.isShowMore)
                return this.profile.about_content.substring(0, 100);
            else
                return this.profile.about_content;
        },
        showMoreBtnText() {
            return this.isShowMore ? this.get_word("show_less") : this.get_word("show_more");
        }
    },
    methods: {
        toggleNotifications(){
             let channelNotifications = JSON.parse(localStorage.getItem('channelNotifications'));
            if (!this.isNotifications) {
                channelNotifications.push(this.profile.id);
            } else {
                channelNotifications = channelNotifications.filter(noti=>+noti !== +this.profile.id);
            }

            localStorage.setItem('channelNotifications',JSON.stringify(channelNotifications));
            this.isNotifications = !this.isNotifications;
        },
        blat(){
            if (this.profile.user_follow) this.stats.num_followers++;
            else this.stats.num_followers--;
        },
        onScrollEvent() {
            if (!this.on_loading && this.scroll_container.scrollTop + this.scroll_margin >= (this.scroll_container.scrollHeight - this.scroll_container.offsetHeight)) {
                this.load_results();
            }
        },
        load_results() {
            if (this.on_loading)
                return;
            this.page++;
            if (this.page == 1) {
                this.items = [];
            }
            if (this.stopLoading)
                return;
            this.on_loading = true;
            let postdata = { user_id: this.user_id, all_langs: true };
            postdata.page = this.page;
            this.api({ action: "feed/results", data: postdata }, (data) => {
                this.numResults = data.data.num;
                this.stopLoading = (data.data.items.length == 0);
                if (this.page == 1)
                    this.items = data.data.items;
                else
                    this.items.push(...data.data.items);
                this.on_loading = false;
            });
        }
    }
}

</script>

<style lang="scss" scoped>
    #header_inner{
        #notification_wrapper{
            &{padding: 0 20px;}
            button{font-size: 20px;}
            svg{fill: var(--clr_main);display: block;height: 24px;width: 24px;}
        }
    }

    #profile_page {
        .head {
            &{display: flex;margin-bottom: 10px;}

            .stats {
                &{display: flex; gap: 10px;gap: 40px;margin: 0 auto;align-items: center;}

                .stat{
                    &{display: flex;flex-direction: column;flex: 1;font-size: 17px;align-items: center;}
                    span:first-child{line-height: 1;font-size: 19px;font-weight: 600;}
                }
            }
        }

        .profile_details {
            &{margin-bottom: 20px;}
            .name{margin-bottom: 10px;display: block;text-transform: capitalize;font-weight: bold;font-size: 18px;}

            .desc{margin-bottom: 20px;display: inline;}

            .show_more{margin-inline-start: 10px;background-color: transparent;color: var(--clr_primary);border: none;text-decoration: underline;}

        }
        
        .actions_wrapper { display: flex;gap: 10px;margin-bottom: 20px;
            .btn { flex: 1;}
        }

        .tab_toggle {
            &{display: flex;}

            button{
                &{flex: 1;padding: 10px 15px;color: var(--clr_main);font-size: 24px;box-shadow: inset 0px 0 0 0px #fff;transition: all .2s;}

                &:not(.active){color: var(--clr_btn_disabled);}

                &.active{box-shadow: inset 0px -2px 0 0px var(--clr_main);}
            }
        }

        .content_grid {
            &{display: flex; gap:10px; flex-direction: column;padding: 10px;}

            li{ 
                &{display: flex;gap: 10px;}
                span.pic { display: block;height: 100px; width: 200px; background-size: cover;background-repeat: no-repeat;background-position: center;flex-basis: 50%;}
                .content_wrapper {
                    &{flex-basis: 50%;}
                    h3{color: var(--clr_main); overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3; /* number of lines to show */-webkit-box-orient: vertical;margin-top: 5px;line-height: 1;}
                    .duration_wrapper{
                        &{display: flex;gap: 5px;align-items: center;font-size: 16px;}
                        *{color: var(--clr_main);}
                        i{font-size: 15px;}
                    }
                    .date{color: var(--clr_date);}
                    .short_text{max-width: 20ch; color: var(--clr_main); overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2; /* number of lines to show */-webkit-box-orient: vertical;margin-top: 5px;line-height: 1;}
                }
            }
        }
        // .content_grid {
        //     &{display: grid;grid-template-columns: repeat(3, 1fr); gap:2px; }

        //     li{ 
        //         span { display: block; height: 33vw;background-size: cover;background-repeat: no-repeat;background-position: center;}
                
        //     }
        // }

        .lesson_list {
            &{display: flex;flex-direction: column;gap: 10px;padding-top: 10px;}

            li{
                &{padding: 10px;min-height: 170px;display: flex;}
                &:not(:last-child){border-bottom: #cccccc36 solid 1px;}
                .content {
                    &{flex: 1;}
                    h2{font-size: 32px;}

                    .location{margin-bottom: 20px;display: block;color: rgb(106, 106, 106);}

                    .details {
                        &>div{
                            &{display: flex;gap: 10px;font-size: 17px;}
                            &:first-child{margin-bottom: 10px;}
                            
                            
                        }
                    }

                    a{color: var(--clr_main);}
                }

                .day{
                    &{height: 35px;border-radius: 5px;display: flex;align-items: center;justify-content: center;}
                }

            }
        }
    }
</style>