<template>
    <li class="list_item_widget" v-if="item.pic > 0">
        <span v-if="item.pic_tumb" class="pic" :style="bg(item.pic_tumb)"></span>
        <div class="content_wrapper">
            <h3 v-html="item.title"></h3>
            <p class="short_text" v-if="item.short_text && item.short_text !=''" v-html="item.short_text"></p>
            <p class="duration" v-else v-html="item.duration_parse"></p>
            <span class="date" v-html="item.date_parse"></span>
        </div>
    </li>
</template>

<script>
export default {
    props:{
        item:{
            required: true,
            type: Object
        },

    }
}
</script>

<style lang="scss">
    .list_item_widget {
                &{display: flex;gap: 10px;}
                span.pic { display: block;height: 120px; width: 200px; background-size: cover;background-repeat: no-repeat;background-position: center;flex-basis: 42%;}
                .content_wrapper {
                    &{flex-basis: 50%;}
                    h3{color: var(--clr_main); overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2; /* number of lines to show */-webkit-box-orient: vertical;margin-top: 5px;line-height: 1;}
                    .short_text{max-width: 20ch; color: var(--clr_main); overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2; /* number of lines to show */-webkit-box-orient: vertical;margin-top: 5px;line-height: 1;}
                }
    }
</style>