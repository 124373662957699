<template>



<div id="category_page_wrapper" v-if="loaded">
    <div id="category_page">

        <div class="section_head">
            <h1 class="section_title" v-html="tag.name"></h1>
        </div>

        <div id="category_section">
            
            <section>  
                <div class="items_list category_item">
                    <article v-for="item in items" :key="item.id" >
                        
                        <router-link :to="item.app_link" class="pic"><span :style="bg(item.pic_parsed)"></span></router-link>
                
                        <router-link :to="item.app_link" class="content">
                            <h3 v-text="item.title"></h3>
                        </router-link>
                                            
                    </article>
                </div>
        
            </section>
        </div>
        
    </div>
</div>


</template>

<script>
 
export default {
    data() { 
        return {
            loaded: false,
            on_loading: false,
            page: 0,
            searchTimer: null,
            stopLoading: false,
            
            items: [],
            selectedCategory: false,
            tag_id: this.$route.params.id,
            tag: {}
        };
    },
    mounted(){

        
        this.api({ action: 'tags/get', data: {id: this.$route.params.id}}, (data) => {
            this.tag = data.data;
            this.loaded = true;
        });

        let scrollEl = document.documentElement;

        this.load_results();
        
        document.onscroll = () => {
        let el = document.querySelector('#category_page');
          if  (!this.on_loading && ((scrollEl.scrollTop + (scrollEl.clientHeight * 2)) > el.offsetTop + el.scrollHeight ) )
          {
            this.load_results();
          }
        };

    },
    methods: {

        filter(){
            this.page=0;
            this.stopLoading=false;
            this.load_results();
        },
        
        load_results(){

            if (this.on_loading) return;

            this.page++;

            if (this.page == 1) 
            {
                this.items = [];
            }

            if (this.stopLoading) return;
            this.on_loading = true;

            let postdata = { tag: this.tag_id }
            postdata.page = this.page;
            if (this.selectedCategory) postdata.category = this.selectedCategory;
      
      
            this.api({ action: 'recipes/results', data: postdata, method: 'get' }, (data) => {

              this.numResults = data.data.num;

              this.stopLoading = (data.data.items.length == 0);

              if (this.page == 1) this.items = data.data.items; 
              else this.items.push(...data.data.items); 
              this.on_loading = false;
          });

        
        }
    }
}

</script>

<style lang="scss" scoped>

</style>