<template>
    <a class="btn primary" @click="doAction" :key="item.user_follow">
        <span v-show="!item.user_follow">עקוב</span>
        <span v-show="item.user_follow">הסר עוקב</span>
    </a>
  </template>
  
<script>
export default {
  props: {
      modelValue: Boolean,
      item: {
          type: Object,
          required: true
      }
  },
  methods:{
    doAction()
    {
        if (!this.get_me())
        {
            this.alert('כדי לבצע את הפעולה יש להתחבר לאתר');
            return;
        }

        if (this.modelValue === true) this.remove();
        else this.add();
    },
    updateValue(val) {
        this.$emit('update:modelValue', val);
        this.$emit('followchange');
    },
    add(){
        this.api({ action: 'follow/add', data: {follow_id: this.item.id } }, () => {
            
        });
        this.updateValue(true);
    },
    remove(){
        this.api({ action: 'follow/remove', data: {follow_id: this.item.id } }, () => {
            
        });
        this.updateValue(false);
    },
  }
}
</script>