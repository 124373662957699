<template>
  <div class="widget_post">
    <router-link :to="'/profile/' + itemNew.lecturer.id" class="writer_wrapper">
      <UserIcon :user="itemNew.lecturer" size="sml" />
      <div class="content">
        <span v-html="itemNew.lecturer.display_name"></span>
      </div>
    </router-link>

    <div class="media_wrapper">
      <div
        class="post_pic"
        :style="bg(itemNew.pic_mid)"
        @click="$bus.$emit('open_item', itemNew)"
      ></div>
      <!-- <div v-if="!playVideo" class="post_pic" :style="bg(itemNew.pic_mid)" @click="playVideo=true"></div> -->
      <!-- <video-player v-else :src="itemNew.vid_embed" :controls="true" autoplay="1" :type="itemNew.mime" :poster="itemNew.pic_mid" :volume="0.6" :techOrder="['youtube']" :sources="[{ type: itemNew.mime, src: itemNew.vid_embed}]" :youtube="{ iv_load_policy:1, ytControls: 2 }"  class="vjs-16-9" @play="onPlay" ref="vid" /> -->
    </div>
    <div class="bottom_wrapper">
      <div class="actions_wrapper">
        <like :item="item" v-model="itemNew.user_liked" @likechange="blat" />
        <button
          @click="
            $bus.$emit('second_page', {
              type: 'comments',
              id: item.id,
              item: item,
            })
          "
        >
          <i class="far fa-comment"></i>
        </button>
        <button>
          <i class="far fa-paper-plane"></i>
        </button>
        <bookmark :item="item" v-model="itemNew.user_bookmarked" />
      </div>

      <span
        v-if="itemNew.num_likes && itemNew.num_likes > 0"
        class="like_count"
        v-text="
          itemNew.num_likes > 1
            ? itemNew.num_likes + ' ' + get_word('likes')
            : itemNew.num_likes + ' ' + get_word('like')
        "
      ></span>

      <p class="desc" v-html="itemNew.title"></p>
      <p class="date desc" v-html="itemNew.date_parse"></p>
    </div>
  </div>
</template>

<script>
import UserIcon from '@/components/user_icon.vue'
import Like from './like.vue'
import Bookmark from '../bookmark.vue'
export default {
  components: { Like, UserIcon, Bookmark },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playVideo: false,
      itemNew: this.item,
    }
  },
  methods: {
    onPlay() {
      if (!this.isNull(this.$store.state.playingNow))
        this.$store.state.playingNow.trigger('stop')
      this.$store.state.playingNow = this.$refs.vid
    },
    blat() {
      // this.itemNew.num_likes+(this.itemNew.user_liked ? 1 : -1);
      this.itemNew.num_likes =
        this.itemNew.num_likes * 1 + (this.itemNew.user_liked ? 1 : -1)
    },
  },
}
</script>

<style lang="scss">
.widget_post {
    &{margin-bottom: 20px;}

    .writer_wrapper {
        & {display: flex;gap: 10px;padding: 10px;align-items: center;}

        .content {
          span { font-weight: 600; color: var(--clr_main);}

        }
    }

    .media_wrapper{ 
      .post_pic { background-size: cover; background-position: center center; height: var(--post_height);}
      video { width: 100%;}
    }

    .bottom_wrapper {
      .actions_wrapper {
          &{padding: 10px;display: flex;gap: 15px;}
          button{ background-color: transparent; border: none;font-size: 22px; }
      }

      .like_count{margin-bottom: 10px;display: block;padding: 0 10px;font-weight: 600;}

      .desc{padding: 0 10px;margin-bottom: 5px;}

      .date{color: var(--clr_date);padding: 0 10px;}

      .desc,.like_count{font-size: 18px;line-height: 1.1;}
    }
}
</style>
