<template>
  
<div id="search_page" class="search_popup">
	<div id="close_btn" @click="close_search()" >X</div>  
	<div class="dark_bg"></div>
	<div class="content">
	<div class="centered_md">
		<div class="row">
			
			<ul class="sharing_list">
				<li id="fc_white"><a href="http://www.facebook.com/Hidabroot" target="_blank"></a></li>
				<li id="whatsapp_white"><a href="http://lp.vp4.me/b11r" target="_blank"></a></li>
				<!-- <li id="instagram_white"><a href="https://www.instagram.com/hidabroottv" target="_blank"></a></li>
				<li id="android_white"><a href="https://play.google.com/store/apps/details?id=net.linnovate.hidabroot" target="_blank"></a></li> -->
				<li id="itunes_white"><a href="https://itunes.apple.com/il/app/%D7%94%D7%99%D7%93%D7%91%D7%A8%D7%95%D7%AA/id1006747141?mt=8" target="_blank"></a></li>
			</ul>

			<form id="search_form" action="/search" method="get" @submit.prevent="do_search();">
				<input  id="search_text" type="text" name="q" placeholder="חיפוש" autocomplete="off" v-model="q_val" @keyup="do_search()" @change="do_search()">
				<input type="submit" style="display:none;">
			</form>

		</div> 

		<div class="row">
			<div id="search_results">
				
                <div>
                    <!--
                    <div class="tags">
                        <a :href="'search?q=' + q_val">הכל</a>
                        <a :href="'search?type=videos&q=' + q_val">וידאו</a>
                        <a :href="'search?type=articles&q=' + q_val">מאמרים</a>
                        <a :href="'search?type=events&q=' + q_val">אירועים</a>
                        <a :href="'search?type=faq&q=' + q_val">שאל את הרב</a>
                    </div>
                    -->
                    <div class="clr"></div>

                    <div class="items_list ">

                        <article v-for="item in items" :key="item.id" :class="'item_type_' + item.type">
                           
                            <div class="content">
                                <router-link :to="item.app_link" @click="close_search()"><h3 v-html="item.title"></h3></router-link>
                                <a v-if="item.writer > 0" class="writer" :href="item.writer_link" rel="author">כותב: {{item.writer_name}}</a>, {{item.date_formatted}}
                                    <div v-if="item.type == 'event'">
                                        <div><label>מרצה:</label> <span>{{item.writer}}</span></div>
                                        <div><label>תאריך:</label> <span>{{item.event_heb_date}}</span></div>
                                        <div><label>מיקום:</label> <span v-html="item.short_text"></span></div>
                                    </div>
                                    <p v-else v-html="item.short_text_stripped"></p>
                            </div>
                            
                             <router-link :to="item.app_link" @click="close_search()" class="article_pic mbl_radius" :class="{play: item.type == 'video'}">
                                <div class="search_pic" :style="bg(item.pic_tumb)"></div>
                            </router-link>

                        </article>


                        <div class="next_page">&nbsp;</div>
                    </div>
                    </div>


                <!--
				<div id="search_promotions">
				
					<h3>מומלצים בשבילך:</h3>
                    <div class="container-fluid">
					<div class="row">
                    
					<article v-for="prom in search_promote" :key="prom.id" class="col-xs-4">
						<a :href="prom.url" :target="(prom.target != '' ? prom.target : '')">
						<div class="article_pic">
							<span v-lazy:background-image="prom.pic_tumb"></span>
						</div>
						</a>
							<a :href="prom.url"><h3>{{ prom.title }}</h3></a>
					</article>
                    
					</div>
				</div>
				</div>
                -->
				
			</div>
		</div>
    </div>
	</div> 
</div>

</template>

<script>

export default {
  data() {
    return {
        xhr_timer: false,
        q_val: '',
        page: 1,
      items: []
    };
  },
  methods: {
      close_search(){ this.$emit('closesearch'); },
      do_search(){
          clearTimeout(this.xhr_timer);
          this.xhr_timer = setTimeout(this.do_search_func, 400);
      },
      do_search_func(){
        // categories
        this.api({action: 'search/results', data: { limit:20, q: this.q_val, page: this.page }}, (data) => {
    
            this.items = data.data.items;
        });
      }
  },
  /*
  watch:{
        $route (){
            this.close_search();
        }
    } */

}
</script>

<style lang="scss" scoped>

/* SEARCH PAGE */ 
#search_page.search_popup  {position: fixed;top: 0px;left: 0px; bottom: 0px;width: 100%; z-index: 999999998;overflow-y: scroll;display: block !important;
    .items_list { padding: 0 25px;}
}
#search_page.search_popup .dark_bg { position: fixed;top: 0px;left: 0px;bottom: 0px;right: 0px;background: rgba(0,0,0,0.8);overflow: hidden;z-index: 1;}
#search_page.search_popup #close_btn {   position: fixed;z-index: 3;right: 50px;top: 35px; cursor: pointer;color: #fff;  font-size: 35px;}
#search_page.search_popup #close_btn:after {content: "";color: #fff;font-size: 35px;    font-family: heebo;    font-weight: 100;}
#search_page.search_popup .content { position: relative; z-index: 2;flex: 1; }
#search_page {
    #search_form{padding: 0 15px;}
    .article_pic { display: block; width: 100px; height: 90px; margin-bottom: 8px; }

    .items_list {
        &{display: flex;flex-direction: column;}
        article{
            &{display: flex;gap: 10px;}
            .search_pic{height: 100%;width: 100px; background-size: cover;background-position: center;}
        }
    }
}
#search_page.search_popup  #search_text {background-color: transparent;color: #fefefe; width: 100%;height: auto; border: none; border-bottom: 2px solid #ccc;font-size: 68px; line-height: 50px; padding-inline-start: 10px;  margin-bottom: 35px;}
#search_page.search_popup  #search_text::-webkit-input-placeholder { color:#C4C4C4; }
@media (max-width: 767px)
{
	#search_page.search_popup #search_text {font-size: 22px !important;background-size: 18px;  padding-left: 0px; margin-bottom: 15px;}
	#search_page.search_popup #close_btn { top: 0px; right: 20px; }

}
	

#search_page.search_popup ul {list-style-type: none;}
#search_page.search_popup ul li {width: 100%; display: inline-block; color: #fff;}
#search_page.search_popup ul li aside {padding-top: 20px;}

#search_page.search_popup .items_list article { color: #656565; }
#search_page.search_popup .items_list article p { color: #fff; }

#search_page.search_popup .tags { text-align: center; padding-bottom: 65px; }
#search_page.search_popup .tags a { display: inline-block; height: 40px; line-height: 40px; padding: 0 25px; margin: 0 8px; background: #000; color: #fff; }
#search_page.search_popup .tags a.active { background: #fff; color: #000; }

#search_page.search_popup .items_list article h3 { color:#fefefe; }

#search_promotions {padding-top: 100px; }
#search_promotions .row { margin: 0 -15px; }
#search_promotions > h3 { color: #fff; margin-bottom: 25px; }
#search_promotions article { padding: 15px; }


#search_promotions article h3 { font-size: 16px; margin: 0px; color: #fff; height: 44px; overflow: hidden; }

#search_page .page_title { font-size: 30px; line-height: 33px;    padding-top: 15px; padding-bottom: 5px;}
#search_page .page_title:before {  position: relative;top: -10px; }
#search_page .tags { margin-bottom: 0px !important; }

#search_page:not(.search_popup)
{
  .head_page { margin: 0px -15px 30px -15px; padding: 35px 25px; color: #fff; text-align: center;}
  .head_page h1 { font-size: 48px; font-weight: bold; margin-bottom: 14px;  font-family: 'almoni-dl'; }
  .head_page h2 { font-size: 15px; width: 95%;  font-family: 'almoni-dl';}
  .head_page h2 span { font-weight: bold; font-size: 24px; }
}
</style>



