<template>
  
  <header id="header" :key="$route.matched[0].path">
    <div id="header_inner">
    <router-link to='/' @click="$bus.$emit('close_menu')" id="logo" class="title_cls"></router-link>
<!--     
    <router-link id="go_back_btn" v-if="$routerHistory.hasPrevious() && $route.matched[0].props.default.hide_back !== true" :to="{ path: $routerHistory.previous().path }">חזרה</router-link>
    <router-link id="go_back_btn" v-if="!$routerHistory.hasPrevious() && $route.matched[0].props.default.hide_back !== true" :to="{ path: '/' }">חזרה</router-link>
     -->


    </div>

    

    </header>
</template>

<script>

//import search from './search.vue'

export default {

  data() {
    return {
      //is_search_opened: false,
      
    };
  }
}
</script>

<style lang="scss" scoped>
#user_side { display: flex; gap:10px; align-items: center;}
</style>