<template>
  <div id="login_page_wrap">
    <div id="login_page">
        <div id="form_wrapper" md-theme="default-dark">
          
        <div v-if="is_loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <div v-if="!is_loading">
        <div>
          <template v-if="!isApp">
          <!-- <a class="fb_btn" @click="login_fb()">התחבר באמצעות Facebook</a> -->
          <!-- <a class="g_btn" @click="login_g()">התחבר באמצעות Google</a> -->

          <h2 class="border_title"><span v-html="pageTitle"></span></h2>
          </template>
            <form @submit.prevent="login()" v-if="show_page=='login'">

              <ul>
                <li>
                    <label v-html="get_word('username_label')"></label>
                    <input type="text" v-model="username" name="username" required="">
                    <span class="md-helper-text" v-html="get_word('username_placeholder')"></span>
                </li>

                <li>
                    <label v-html="get_word('_PASSWORD_')"></label>
                    <input type="password" name="password" v-model="password" required="">
                    <span class="md-helper-text" v-html="get_word('password_placeholder')"></span>
                </li>
              </ul>
                <!-- <md-checkbox v-model="remember_me" value="1" class="md-primary">Remember me</md-checkbox> -->


              <div style="margin-bottom:15px; text-align:center;"><input type="submit" value="כניסה" class="btn primary full_width"></div>
              <div class="bottom_links"><a @click="show_page='forgot'" v-html="get_word('forgot_password')"></a><a @click="show_page='signup'" v-html="get_word('no_account')"></a></div>
              
            </form>

            
            <form @submit.prevent="signup()" v-if="show_page=='signup'">

              <ul>
                <li>
                    <label class="required" v-html="get_word('_FULL_NAME_')"></label>
                    <input type="text" v-model="signupForm.display_name" name="display_name" required>
                </li>

                <li>
                    <label class="required" v-html="get_word('email')"></label>
                    <input type="text" v-model="signupForm.username" name="username" required>
                </li>

                <li>
                    <label class="required" v-html="get_word('_PASSWORD_')"></label>
                    <input type="password" v-model="signupForm.password" name="password" required>
                </li>

                <li class="terms_wrapper">
                  <label>
                    <input type="checkbox" v-model="accept_terms" required>{{get_word('terms_text')}} <a @click="alert(get_word('terms_content'))" class="u">{{get_word('the_terms')}}</a>
                  </label>
                </li>

              </ul>


              <div style="margin-bottom:15px; text-align:center;"><input type="submit" value="הרשמה" class="btn primary full_width"></div>
              <div class="bottom_links"><a @click="show_page='login'" v-html="get_word('have_account')"></a></div>
              
            </form>
            
            <form @submit.prevent="forgot()" v-if="show_page=='forgot'">

              <ul>
                <li>
                    <label>אי-מייל *</label>
                    <input type="email" v-model="forgotForm.email" name="email" required>
                </li>
              </ul>


              <div style="margin-bottom:15px; text-align:center;"><input type="submit" :value="get_word('reset_password')" class="btn primary full_width"></div>
              <div class="bottom_links"><a @click="show_page='login'" v-html="get_word('have_account')"></a></div>
              
            </form>
          </div>
        
        </div>
      </div>

  </div>
</div>
</template>

<script>
export default {
  data(){
      return {
        forgotUrl: window.site_url + 'signup/forgotPass',
        FB: {},
        model: {},
        scope: {},

        token: '',
        show_step_b: false,
        is_loading: false,
        username: '',
        password: '',
        remember_me: false,
        
        show_page: 'signup',

        accept_terms: false,

        signupForm: { 
          display_name: '',
          username: '',
          password: ''
        },

        forgotForm: {
          email: ''
        }
      }
  },
  mounted(){
    document.querySelector('input').focus()
  },
  computed:{
    pageTitle() {
      switch(this.show_page) {
        case 'login': {return this.get_word('login');}
        case 'forgot': {return this.get_word('forgot_password_title');}
        default: {return this.get_word('signup');}
      }
    }
  },
  methods:{
    login(){
      this.is_loading = true;


      const formData = { 
        password: this.password,
        username: this.username 
      };

      this.api({ 'action': 'login', data: formData}, (data) => {
        
          if (this.isNull(data) || this.isNull(data.data))
          {
              this.is_loading = false;
              this.alert(this.get_word('wrong_details'));
              //alert('Wrong details. Please try again.');
          }
          else
          {
              this.set_user_token(data.data);
              if (this.isApp) this.afterLogin();
  //            document.location = document.location.href;
              this.$bus.$emit('reload');
          }
          
      });

    },

    signup(){
      this.is_loading = true;

      if (!this.accept_terms) return false;

      this.api({ 'action': 'signup', data: this.signupForm}, (data) => {
        
          if (this.isNull(data) || this.isNull(data.data))
          {
              this.is_loading = false;
              this.show_error = true;
              //alert('Wrong details. Please try again.');
          }
          else
          {
            alert(data.data.token);
            this.set_user_token(data.data.token);
            if (this.isApp) this.afterLogin();
//            document.location = document.location.href;
            this.$bus.$emit('reload');
          }
          
      });

    },

    forgot(){
      this.is_loading = true;

      this.api({ 'action': 'forgot_password', data: this.forgotForm}, (data) => {
        
        this.is_loading = false;

        if (data.data === true) {
          this.alert(this.get_word('forgot_pass_step1_finish'))
        }
        else {
          this.alert(this.get_word('user_not_found'));
        }
          
      });

    },    

    afterLogin(){
      var self = this;

      window.cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
          
          window.cordova.plugins.firebase.messaging.subscribe("main");

          window.cordova.plugins.firebase.messaging.getToken().then(function(token) {

            self.api({ 'action': 'user/set_push_token', data: {'token': token}}, function(){

            });
          });
      });

    },
    login_fb(){

      if (this.isApp)
      {
        window.login_fb();
      }
      else
      {
        document.location = window.site_url + '/cfacebook/login';
      }
      
    },
    login_g(){
      if (this.isApp)
      {
        window.plugins.googleplus.login(
          {
            'scopes': 'profile email', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
            'webClientId': '216641503463-ea7fclo30kea3ep3vqektgg2g17d8ugu.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
            'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
          },
          function (obj) {
            alert(JSON.stringify(obj)); // do something useful instead of alerting
          },
          function (msg) {
            alert('error: ' + msg);
          }
        );

      } 
      else
      {
        document.location = window.site_url + 'cgoogle/login';
      }
    },

  }
}
</script>

<style lang="scss" scoped>

ul { 
  li { display: block; margin-bottom: 15px;
    label { display: block; font-size: 17px; color: rgba(0, 0, 0, 0.9);  padding-right: 15px;}
    input:not([type=checkbox]) { width:100%; height: 50px; background: #eee; border:none; border-radius: 50px; padding: 0 25px; font-size: 20px; }
    span { display: block; text-align: left; font-size: 15px; color: rgba(0, 0, 0, 0.5); padding-left: 15px;}
  }
}

.md-field label { left: auto; right: 0;}
.md-field .md-count, .md-field .md-error, .md-field .md-helper-text { left: 0;}


#login_page_wrap {
   &{height: 100vh; display: flex; padding-top: 20%;}
   *{color: var(--clr_main);}
  .border_title { color: #A2A2A2; font: 23px sans-serif;  margin-top: 30px; text-align: center; position: relative; z-index: 1; margin: 15px 0px;    
    span { background: var(--clr_main2); padding: 0 15px; color: var(--clr_main);}
    &::before { border-top: 2px solid #dfdfdf; content: ""; margin: 0 auto; position: absolute; top: 50%; left: 0; right: 0; bottom: 0; width: 95%; z-index: -1; }
  }
  input[type=text], input[type=password], input[type=email]{outline: none;color: #121212;}
#login_page { width:400px; max-width:80%; margin:0 auto; } 
#logo { display: block; height: 80px; width:200px; margin: 0 auto; background: url('../assets/images/logo.png') center center no-repeat; }
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px #eee inset !important;
}

.fb_btn, .g_btn { text-align: center; width: 100%; height: 48px; line-height: 48px; border-radius: 6px; display: inline-block; color: #fff !important;   font-size: 16px; padding: 0 15px; padding-left: 41px; margin-bottom: 15px;  }
.fb_btn { background: #30487b /*url(../images/f_ico.png)*/ 10px center no-repeat; }
.g_btn {  background: #f14338 /*url(../images/f_ico.png)*/ 10px center no-repeat; }


.bottom_links { text-align:center; display:flex; justify-content: space-between; 
  a { text-decoration: underline;}
}
}

</style>