<template>
  <div id="settings_page">
    <div class="page_top">
        <h1 v-html="get_word('settings_ttl')"></h1>
        <button v-if="selectedCatId > 0" @click="selectedCatId = 0" class="back clean_btn">
            <i class="fas fa-long-arrow-alt-left"></i>
        </button>
    </div>
    <ul v-if="selectedCatId === 0" class="main_settings">
        <li>
          <span v-html="get_word('dark_mode')"></span>
          <div class="theme-switch-wrapper">
            <label class="theme-switch" for="checkbox">
                <input :checked="isDarkMode" @change="changeTheme" type="checkbox" id="checkbox" />
                <div class="slider round"></div>
            </label>
          </div>
        </li>

        <li>
          <span v-html="get_word('language')"></span>
          <select name="lang" id="lang" @change="setLang">
            <option v-for="setting in langSettings" :value="setting.id" :key="setting.id" v-text="setting.title" :selected="isSelectedLang(setting.id)"></option>
          </select>
        </li>

        <li class="setting" v-for="setting in settings" @click="selectedCatId = setting.id" :key="setting.id">
            <span v-html="setting.title"></span>
            <i class="fas fa-chevron-left"></i>
        </li>
    </ul>

    <ul v-if="selectedCatId > 0" class="main_settings">
        <li class="setting" v-for="setting in selectedCat.innerSettings" :key="setting.id">
            <span v-html="setting.title"></span>
            <div class="theme-switch-wrapper">
                <label class="theme-switch" for="checkbox">
                    <input v-model="setting.isActive" type="checkbox" id="checkbox" />
                    <div class="slider round"></div>
                </label>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
    data() {
        return {
            settings:[
                {
                    id: 1, title: this.get_word('notification_settings'),
                    innerSettings:[
                        {id: 1, title: this.get_word('all_notifications'), isActive: false},
                        {id: 2, title: this.get_word('channel_notifications'), isActive: false},
                        {id: 3, title: this.get_word('lesson_notifications'), isActive: false},
                        {id: 3, title: this.get_word('comment_notifications'), isActive: false},
                    ]
                },
            ],
            langSettings:[
                {id: 1, title: 'עברית'},
                {id: 4, title: 'English'},
                {id: 5, title: 'français'},
            ],
            selectedCatId: 0
        }
    },
    computed:{
        selectedCat() {
            return this.settings.find(setting=>setting.id === this.selectedCatId);
        },
        isDarkMode() {
            return localStorage.getItem('theme') === 'dark';
        },
    },
    methods:{
        changeTheme({target}){
            if (target.checked) {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
            }
            else {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
            }    
        },
        setLang({target:{value:langId}}){
            localStorage.setItem('langId', langId);
            this.$emit('setLang', ()=>{
                    this.settings[0].title = this.get_word('all_notifications');
                    this.settings[0].innerSettings = [
                        {id: 1, title: this.get_word('all_notifications'), isActive: false},
                        {id: 2, title: this.get_word('channel_notifications'), isActive: false},
                        {id: 3, title: this.get_word('lesson_notifications'), isActive: false},
                        {id: 3, title: this.get_word('comment_notifications'), isActive: false},
                    ]
                });
        },
        isSelectedLang(id){
            return localStorage.getItem('langId') ? +localStorage.getItem('langId') === id : id === 1;
        }
    }
}
</script>

<style lang="scss">
    #settings_page {
        &{padding: 10px;}

        .page_top{
           &{display: flex; align-items: center;justify-content: space-between;margin-bottom: 20px;}
           button{font-size: 20px;}
        }


        ul {
            &{padding: 0 20px;display: flex;flex-direction: column;gap: 15px;}
            li{justify-content: space-between;display: flex;align-items: center;font-size: 19px;text-transform: capitalize;}
        }

        select{
            &{width: fit-content;border: none;font-size: 16px;background-color: #cccccc6b;color: var(--clr_main);}
            option{background-color: #a3a3a3;color: var(--clr_main);}
        }
    }
</style>