<template>
  
    <section class="centered" id="donation_page">	
        <div id="donation_form" class="centered">
            <h1 v-html="get_word('donation_title1')"></h1>
            <div class="steps_wrapper">
                <div class="step_box" v-for="step_num in steps" :key="step_num" v-html="step_num" :class="{ active: step >= step_num }"></div>
            </div>
            <form ref="step2" v-if="step === 1" class="step2" @submit.prevent="handleStepSubmit">
                <h3 v-html="get_word('step2_subtitle')"></h3>
                <div class="form_group donation_type">
                    <label class="required bold" for="donation_type" v-html="get_word('donation_type')"></label>
                    <select required v-model.number="donationType" class="input_hollow" id="donation_type">
                        <option v-for="type in donationTypes" :value="type.id" :key="type" v-html="type.name"></option>
                    </select>
                </div>
                <div class="form_group donation_amount">
                    <label class="required bold" for="donation_amount" v-html="get_word('donation_amount')"></label>
                    <select required v-model.number="donationAmount" class="input_hollow" name="donation_amount" id="donation_amount">
                        <option v-for="amount in donationAmounts" :value="amount.value" :key="amount.value" v-html="getFormattedAmount(amount.value)" @click="customAmount = false"></option>
                        <option :value="Infinity" v-html="get_word('other')"></option>					
                    </select>
                    <input class="custom_amount" min="5" v-if="donationAmount === Infinity" type="number" v-model.number="customAmount" :placeholder="get_word('insert_amount')" :oninvalid="this.setCustomValidity(get_word('donation_amount_too_small'))" oninput="this.setCustomValidity('')" required>
                    
                </div>
                <div class="form_group payment_period">
                    <label class="required bold" for="payment_period" v-html="get_word('payment_period')"></label>
                    <select required v-model.number="paymentPeriod" class="input_hollow" id="payment_period">
                        <option v-for="period in donationPeriods" :value="period.value" :key="period" v-html="period.name"></option>
                    </select>
                </div>
                <div class="form_group total_amount">
                    <label class="bold" for="total_amount" v-html="get_word('total_amount')"></label>
                    <div v-html="totalDonationAmount" class="input_hollow" id="total_amount"></div>
                </div>
                <div class="toggle_step_wrapper">
                    <button :disabled="step === 3" class="btn primary continue" type="submit" v-html="get_word('continue')"></button>
                </div>
            </form>
                <form v-if="step === 2" class="step1" @submit.prevent="handleStepSubmit">
                <h3 v-html="get_word('step1_subtitle')"></h3>
                <div class="form_group first_name">
                    <label class="required bold" for="first_name" v-html="get_word('_FIRST_NAME_')"></label>
                    <input v-model="firstName" required class="input_hollow" id="first_name" type="text">
                </div>
                <div class="form_group last_name">
                    <label class="required bold"  for="last_name" v-html="get_word('_LAST_NAME_')"></label>
                    <input v-model="lastName" required class="input_hollow" name="last_name" id="last_name" cols="30" rows="10"/>
                </div>
                <div class="form_group email">
                    <label class="required bold"  for="email" v-html="get_word('_EMAIL_')"></label>
                    <input v-model="email" required class="input_hollow" id="email" type="email">
                </div>
                <div class="form_group phone">
                    <label class="required bold"  for="phone" v-html="get_word('phone')"></label>
                   
                    <input v-model="phone" pattern="[0-9]{9,}" oninvalid="this.setCustomValidity('מספר טלפון אינו תקין')" oninput="this.setCustomValidity('')" required class="input_hollow" id="phone" type="tel">
                    
                </div>
                <div class="checkbox_wrapper">
                    <input id="donation_list_approve" v-model="donationListApprove" type="checkbox">
                    <label for="donation_list_approve" v-html="get_word('step1_checkbox_text')"></label>
                </div>
                <div class="checkbox_wrapper">
                    <input id="terms" type="checkbox" required>
                    <label for="terms" v-html="get_word('_ACCEPT_TERMS_')"></label>
                </div>
                <div class="toggle_step_wrapper">
                    <button  @click="changeStep(-1)" class="btn primary go_back" v-html="get_word('_GO_BACK_')"></button>
                    <button :disabled="step === 3" class="btn primary continue" type="submit" v-html="get_word('continue')"></button>		
                </div>
            </form>
            <div v-if="step === 3" class="step3">
                <iframe :src="siteUrl() + 'payments/do_redirect/'+paymentId" width="100%"></iframe>
            </div>
        </div>
    </section>

</template>

<script>
export default {
data(){
    return {
        steps: [1, 2, 3],
        step: 1,
        // step 2 fields
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        donationListApprove: false,
        // step 1 fields 
        donationTypes: [],
        donationType: '',
        donationAmounts: [],
        donationAmount: 0,
        customAmount: 0,
        isCustomAmount: false,
        donationPeriods: [],
        paymentPeriod: 0,
        // step 3 fields   
        paymentId: null
    } 
},
methods: {
    handleStepSubmit() {
        switch(this.step) {
            case 1:
                this.step++;
                break;
            case 2:
                this.saveDonationInfo();
                break;
        }
        
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    saveDonationInfo() {
        const finalDonationAmount = this.donationAmount === Infinity ? this.customAmount : this.donationAmount;
        const data = { firstName: this.firstName,lastName: this.lastName, email: this.email, phone: this.phone, donationType: this.donationType, donationAmount: finalDonationAmount, paymentPeriod: this.paymentPeriod, donationListApprove: this.donationListApprove };
        
        this.api({ action: 'donate',data}, (res) => {
            if(res === 'error') return this.alert(this.get_word('server_error'));
            if(+res.data > 0) {
                this.paymentId = res.data;
                this.step++;
            } else {
                this.alert(this.get_word('server_error'));
            }
                
        });
    },
    changeStep(change) {
        this.step += change;
    },
    getFormattedAmount(amount) {
        return `${ amount }.00₪`;
    }
},
mounted(){
    this.api({ action: 'donation_data', method: 'get'}, (data)=>{
        this.donationAmounts = data.data.donation_amounts;
        this.donationPeriods = data.data.donation_periods;
        this.donationTypes = data.data.donation_types;

    });
},
computed: {
    totalDonationAmount() {
        const amount = this.donationAmount === Infinity ? this.customAmount : this.donationAmount;
        
        if(amount === Infinity || this.paymentPeriod === 0 || amount === 0 || !amount) {
            return '';
        } else if (this.paymentPeriod < 900 && amount > 0 && this.paymentPeriod > 0) {
            return `${ amount }₪ x ${ this.paymentPeriod } = ${ amount * this.paymentPeriod }.00₪`;
        } else {
            return `${ amount }.00₪ ${ window.CRM.monthly_payment }`;
        }

    }
}
}
</script>

<style lang="scss">
    #donation_page {
        &{display: flex;justify-content: center;padding-top: 40px;}
        @media(min-width: 600px){}
        @media(max-width: 600px){flex-direction: column;}
        
        div#donation_form {
            h1 {
                &{text-align: center;color: var(--clr_main);margin-bottom: 20px;line-height: 1.1;}
                @media(min-width: 600px){font-size: 38px;}
                @media(max-width: 600px){}
            }
            .steps_wrapper {
                &{display: flex;align-items: center;gap: 100px;justify-content: center;margin-bottom: 20px;}
                @media(min-width: 600px){}
                @media(max-width: 600px){gap: 70px;}
                .step_box{
                    &{background-color: var(--color1);border-radius: 5px;width: 60px;height: 60px;padding: 15px;font-weight: 700;color: #fff;text-align: center;position: relative;opacity: 0.25;display: flex;align-items: center;justify-content: center;font-size: 27px;}
                    &:not(:last-child)::after{
                        &{background-color: var(--color1);content: "";width: 100px;height: 3px;display: block;position: absolute;top: 50%;left: 0;transform: translateX(-100%);opacity: 0.25;}
                        @media(min-width: 600px){}
                        @media(max-width: 600px){width: 70px;}
                    }
                    
                    &.active {
                        &,&:not(:last-child)::after {opacity: 1;}
                    }
                }
            }
            form {
                &{margin: 0 auto;}
                h3{
                    &{grid-column: 1/-1;text-align: center;font-size: 29px;margin-bottom: -20px;}
                    @media(min-width: 600px){}
                    @media(max-width: 600px){line-height: 1.2;}
                }

                &.step1 {
                    @media(min-width: 600px){display: grid;grid-template-columns: repeat(2, 1fr);row-gap: 25px;column-gap: 15px;}
                    @media(max-width: 600px){display: flex;flex-direction: column;gap: 20px;}
                    .checkbox_wrapper{display: flex;align-items: center;gap: 8px;}
                }
                
                &.step2 {
                    @media(min-width: 600px){display: grid;grid-template-columns: repeat(3, 1fr);row-gap: 25px;column-gap: 15px;}
                    @media(max-width: 600px){display: flex;flex-direction: column;gap: 20px;}
                    .form_group {
                        &:first-of-type {grid-column: 1/-1;}
                    }
                }

                .form_group {
                    &{display: flex;flex-direction: column;}
                    input, select{width: 100%;border: solid 1px var(--clr_tertiary);padding: 16px;background-color: transparent;border-radius: 5px;padding: 16px;height: 48px;outline: none;}
                    input.custom_amount{border-top: none;}
                    select{font-size: 18px;padding: 0 16px;}
                    div.input_hollow{height: 48px;display: flex;align-items: center;justify-content: flex-end;direction: ltr;}
                }

                .toggle_step_wrapper{
                    &{grid-column: 1/-1;display: flex;justify-content: flex-end;gap: 20px;margin-top: 15px;}
                        .btn_primary{
                            &{height: 50px;font-weight: 700;width: 50%;max-width: 250px; height: 50px;font-weight: 700;letter-spacing: 0.05em;font-size: 23px;border-radius: 5px;color: #fefefe;padding: 5px 1em;font-weight: bold; cursor: pointer;transition: all .2s;text-align: center;justify-content: center;}
                            @media(min-width: 600px){}
                            @media(max-width: 600px){font-size: 20px;white-space: nowrap;padding: 5px 10px;}
                            &.continue {
                                &::after{content: "←";margin: 0 5px;}
                            }
                            &.go_back {
                                &::before{content: "→";margin: 0 5px;}
                            }
                            &:hover{color: var(--color);}
                            &:disabled{opacity: 0.25;}
                        }			
                }
            }
            
            .step3 {
                iframe{height: 600px;border:none;}
            }
        }	

    }



    .ltr {
        #donation_page {
            div#donation_form {
                .steps_wrapper {
                    .step_box:not(:last-child)::after{transform: translateX(100%);right: 0;left:auto;}
                }
            }
        }
        
    }
</style>