<template>
  <div id="about_page" class="centered">
	<div class="bg_pic"></div>
	<div id="top_wrapper">
		<div class="about_logo"></div>
		<h1 class="gradient_txt" v-html="get_word('about_title')"></h1>
		<p v-html="get_word('about_subtitle1')"></p>
		<p class="bold" v-html="get_word('about_subtitle2')"></p>
	</div>
	
	<ul id="about_items">
		<li v-for="(item, idx) in items" class="about_item" :key="item.id">
			
			<!-- <div class="pic" style="background-image: url({$item.pic_parsed})"></div> -->

			<div class="content_wrapper">
				<div class="num" v-text="idx + 1"></div>
				<h3 class="gradient_txt" v-html="item.title"></h3>
				<h4 v-html="item.subtitle"></h4>
				<div class="main_text_wrapper" v-html="item.main_text"></div>
				<div class="bottom_text_wrapper" v-html="item.bottom_text"></div>
			</div>
		</li>
	</ul>
</div>
</template>

<script>
export default {
    data() {
        return {
            items: []
        }
    },
    mounted(){
        this.api({ action: 'about', method: 'get'}, (data)=>{
            this.items = data.data.items
        })
    }
}
</script>

<style lang="scss">
#about_page {

	.bg_pic{position: absolute;background-repeat: no-repeat;background-size:cover;background-position:center;
		/*background: linear-gradient(180deg, rgb(14 17 40 / 0%) 3%, rgb(14 17 40 / 0%) 27%, rgb(14 17 40 / 0%) 65%, rgb(14 17 40) 100%), url(@/assets/images/about_bg.png)*/;opacity: 0.7;inset: 0;height: 1000px;z-index: -1;}
	
	#top_wrapper {
		&{margin: 50px auto;display: flex;flex-direction: column;align-items: center;text-align: center;}
		.about_logo{background-image: url(@/assets/images/about_icon.png);background-repeat: no-repeat;background-size:contain;background-position:center;margin-bottom: 15px;width: 105px;height: 80px;}
		
		h1 {
			&{max-width: 22ch;margin-bottom: 10px;line-height: 1;}
			@media(min-width: 600px){}
            @media(max-width: 600px){font-size: 28px;}
		}
		
		p {
			&{line-height: 1;margin-bottom: 10px;}
			@media(min-width: 600px){width: 80%;max-width: 900px;}
            @media(max-width: 600px){font-size: 19px;}
		}
	}
	
	#about_items {
		@media(min-width: 600px){}
        @media(max-width: 600px){padding: 0 15px;}
		li.about_item {
			&{display: flex; gap: 40px;margin-bottom: 80px;align-items: center;}
			
			@media(min-width: 600px){
				&{padding: 0 30px;}
				&:nth-child(even){flex-direction: row-reverse;}
			}
            @media(max-width: 600px){flex-direction: column;}
			&>*{flex: 1;}
			.pic{background-repeat: no-repeat;background-size:contain;background-position:center;min-height: 550px;}
			
			.content_wrapper {
				&{position: relative;}
				.num{position: absolute;top: 0;inset-inline-start: -4%;color: var(--clr_link);opacity: 0.45;font-size: 180px;font-weight: bold;line-height: 1;transform: translateY(-35%);z-index: -1;}
				h3{
					&{font-size:34px;}
				}
				
				h4{
                @media(min-width: 600px){}
                @media(max-width: 600px){line-height: 1;margin-bottom: 8px;}
				}
				
				ul{list-style-type: circle;}
				
				.main_text_wrapper, .bottom_text_wrapper{
					&{font-size: 17px;}
					p{line-height: 1.2;}
				}
				
				.bottom_text_wrapper{color: var(--color_link);margin-top: 10px;}
			}
		}
	}
}
</style>