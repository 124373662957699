<template>
  
<div id="search_page" class="search_popup">
    <div class="filter_toggle">
        <button class="clean_btn" :class="{active: filter === 'rabbis'}" @click="filter = 'rabbis'" v-html="get_word('_rabbanim')"></button>
        <button class="clean_btn" :class="{active: filter === 'videos'}" @click="filter = 'videos'" v-html="get_word('videos')"></button>
    </div>
	<div v-if="filter === 'videos'" class="centered_md videos_search">
		<div class="row">
			<form id="search_form" action="/search" method="get" @submit.prevent="do_search();" :class="{ focus: isSearchFocus }">
				<div class="flex_wrapper">
                    <input ref="search" :class="{search_focus: isSearchFocus}" @focus="isSearchFocus = true" @blur="isSearchFocus = false" id="search_text" type="text" name="q" :placeholder="get_word('_SEARCH_')" autocomplete="off" v-model="q_val" @keyup="do_search()" @change="do_search()">
                    <div class="search_icon">
                        <svg width="35" height="35" viewBox="0 0 35 35"  xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.6445 0C12.7289 0 6.28907 6.4175 6.28907 14.3056C6.28907 17.776 7.53554 20.9618 9.6058 23.4416L0.403738 32.5494C-0.131994 33.0796 -0.134933 33.9423 0.397108 34.4762C0.664393 34.7443 1.01576 34.8785 1.3672 34.8785C1.71549 34.8785 2.06391 34.7466 2.33058 34.4827L11.5452 25.3625C14.0238 27.3917 17.194 28.6113 20.6445 28.6113C23.1073 28.6113 25.5374 27.9789 27.6722 26.7824C28.3303 26.4136 28.5638 25.583 28.1936 24.9271C27.8235 24.2713 26.9899 24.0385 26.3318 24.4074C24.6054 25.375 22.6388 25.8864 20.6445 25.8864C14.2367 25.8864 9.02345 20.6912 9.02345 14.3056C9.02345 7.92 14.2367 2.72488 20.6445 2.72488C27.0524 2.72488 32.2656 7.92 32.2656 14.3056C32.2656 16.3173 31.7404 18.2982 30.7469 20.0341C30.3728 20.6877 30.6012 21.5197 31.2571 21.8926C31.913 22.2653 32.7479 22.0377 33.122 21.3842C34.3506 19.2377 35 16.79 35 14.3056C35 6.4175 28.5601 0 20.6445 0Z" />
                        </svg>
                    </div>
                </div>
                <div class="close_search_wrapper">
                    <button v-html="get_word('cancel')" class="btn_close_search clean_btn" @click="closeSearch"></button>
                </div>
                
				<input type="submit" style="display:none;">
			</form>
		</div> 

		<div class="row">
			<div id="search_results" v-if="items.length && q_val">
                    <div class="items_list ">
                        <article v-for="item in items" :key="item.id" :class="'item_type_' + item.type">
                            <div class="content">
                                <a @click="$bus.$emit('open_item', item)"><h3 v-html="item.title"></h3></a>

                                <div v-if="item.lecturer" class="writer_wrapper">
                                    <UserIcon :user="item.lecturer" size="sml"/>
                                    <span class="writer" v-if="item.lecturer" >
                                         {{item.lecturer.display_name}}
                                    </span>
                                </div>
                                <div class="date" v-else v-html="item.date_parse"></div>

                            </div>
                            
                             <a  @click="$bus.$emit('open_item', item)" class="article_pic mbl_radius" :class="{play: item.type == 'video'}">
                                <div v-if="item.pic_tumb" class="search_pic" :style="bg(item.pic_tumb)"></div>
                            </a>

                        </article>
                    </div>

				
			</div>
            <div id="search_cats" v-else>
                <h3 v-html="get_word('search_cat_title')"></h3>
                <ul>
                    <li v-for="cat in cats" @click="$bus.$emit('second_page', {type: 'category', category: cat})" :key="cat.id">
                        <div class="inner_wrapper">
                            <div class="pic" v-if="cat.pic_tumb"></div>
                            <span v-html="cat.name"></span>
                        </div>
                        <i class="fas fa-chevron-left"></i>
                    </li>
                </ul>
            </div>
		</div>
    </div>
    <rabbies v-else/>
</div>

</template>

<script>
import UserIcon from '@/components/user_icon.vue';
import Rabbies from './rabbies.vue';

export default {
  components: { UserIcon, Rabbies },
  data() {
    return {
        xhr_timer: false,
        q_val: '',
        page: 1,
        items: [],
        cats: [],
        isSearchFocus: false,
        filter: 'rabbis'
    };
  },
  methods: {
      close_search(){ this.$emit('closesearch'); },
      do_search(){
          clearTimeout(this.xhr_timer);
          this.xhr_timer = setTimeout(this.do_search_func, 400);
      },
      do_search_func(){
        // categories
        if (this.q_val === '') return this.items = [];
        this.api({action: 'search/results', data: { limit:20, q: this.q_val, page: this.page, platform_id: localStorage.getItem('langId') || 1 }}, (data) => {
            this.items = data.data.items;
        });
      },
      closeSearch(){
        this.$refs.search.blur();
        this.isSearchFocus = false;
      }
  },
  mounted(){
    const {filter} = this.$route.query;
    if (filter === 'rabbis' || filter === 'videos') this.filter = filter; 
    this.api({ action: 'vod/categories', data:{platform_id: localStorage.getItem('langId') || 1 }}, (data)=>{
        this.cats = data.data;
    })
  }
  /*
  watch:{
        $route (){
            this.close_search();
        }
    } */

}
</script>

<style lang="scss" scoped>

@keyframes scale-in-hor-left {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}


/* SEARCH PAGE */ 
#search_page.search_popup  {
    &{overflow-y: scroll;padding: 0 15px;padding-top: 20px;}

    #search_form{
        &{margin-bottom: 20px;position: relative;display: flex;gap: 10px;}
        .flex_wrapper{flex: 1;}
        .close_search_wrapper{display: flex;justify-content: center;align-items: center;}
        &.focus{
            // #search_text{width: calc(100% - 40px);}
            .btn_close_search{width: 45px;}
        }
        .search_icon{
            &{position: absolute;top: 50%;inset-inline-start: 10px;transform: translateY(-40%);}
            svg{fill: var(--clr_main);width: 24px;height: 24px;}
        }

        .btn_close_search{display: inline-block;font-size: 16px;font-weight: bold; color: var(--color1);width: 0;overflow: hidden;transition: all .2s;}
    }
}
#search_page.search_popup #close_btn {   position: fixed;z-index: 3;right: 50px;top: 35px; cursor: pointer;color: #fff;  font-size: 35px;}
#search_page.search_popup #close_btn:after {content: "";color: #fff;font-size: 35px;    font-family: heebo;    font-weight: 100;}
#search_page.search_popup .content {
     &{position: relative; z-index: 2;flex: 1;width: 60%;}
     .writer_wrapper{
        &{display: flex;gap: 10px;margin-top: 8px;align-items: center;}
     }
}


#search_page {
    .article_pic { display: block; width: 100px; height: 90px; margin-bottom: 8px; }

    .filter_toggle {
        &{margin: 0 -15px; margin-bottom: 15px;}
        button{
            &{width: 50%;height: 48px;font-size: 17px;transition: all .25s ease;border-bottom: 2px solid transparent;}
            &.active{border-bottom: solid 2px var(--color1);color: var(--color1);}
        }
    }

    .items_list {
        &{display: flex;flex-direction: column;}
        article{
            &{display: flex;gap: 10px;}
            .search_pic{height: 100%;width: 100px; background-size: cover;background-position: center;}
        }
    }
}
#search_page.search_popup  #search_text {
     &{background-color: transparent;margin-inline-end: 0px; color: var(--clr_main);transition: all .2s; width: 100%; border: 1px solid var(--clr_border);border-radius:6px; font-size: 68px; line-height: 40px; padding-inline-start: 40px;}

}
#search_page.search_popup  #search_text::-webkit-input-placeholder { color:#C4C4C4; }
@media (max-width: 767px)
{
	#search_page.search_popup #search_text {font-size: 22px !important;background-size: 18px; }
	#search_page.search_popup #close_btn { top: 0px; right: 20px; }

}
	

#search_page.search_popup {

    #search_cats {
        h3{margin-bottom: 10px;}
        ul {
            li{
                &{padding: 0 15px; color: var(--clr_main);display: flex;align-items: center;justify-content: space-between;border-bottom: 0.5px solid var(--clr_border);padding-bottom: 10px;margin-bottom: 10px;}
                span{font-size: 18px;}
            }
        }
    }
}
#search_page.search_popup ul li {
    &{width: 100%; display: inline-block; color: var(--clr_main);}
    
}
#search_page.search_popup ul li aside {padding-top: 20px;}

#search_page.search_popup .items_list article { color: #656565; }
#search_page.search_popup .items_list article p { color: var(--clr_main); }

#search_page.search_popup .tags { text-align: center; padding-bottom: 65px; }
#search_page.search_popup .tags a { display: inline-block; height: 40px; line-height: 40px; padding: 0 25px; margin: 0 8px; background: #000; color: #fff; }
#search_page.search_popup .tags a.active { background: #fff; color: #000; }

#search_page.search_popup .items_list article h3 { color:var(--clr_main); }

#search_promotions {padding-top: 100px; }
#search_promotions .row { margin: 0 -15px; }
#search_promotions > h3 { color: #fff; margin-bottom: 25px; }
#search_promotions article { padding: 15px; }


#search_promotions article h3 { font-size: 16px; margin: 0px; color: #fff; height: 44px; overflow: hidden; }

#search_page .page_title { font-size: 30px; line-height: 33px;    padding-top: 15px; padding-bottom: 5px;}
#search_page .page_title:before {  position: relative;top: -10px; }
#search_page .tags { margin-bottom: 0px !important; }

#search_page:not(.search_popup)
{
  .head_page { margin: 0px -15px 30px -15px; padding: 35px 25px; color: #fff; text-align: center;}
  .head_page h1 { font-size: 48px; font-weight: bold; margin-bottom: 14px;  font-family: 'almoni-dl'; }
  .head_page h2 { font-size: 15px; width: 95%;  font-family: 'almoni-dl';}
  .head_page h2 span { font-weight: bold; font-size: 24px; }
}
</style>



