<template>
  <div id="questions_page" class="centered">
        <div class="top_container">
            <h2 v-html="pageTitle"></h2>
            <routerLink to="/faq" class="btn primary" v-html="get_word('back_to_faq')"></routerLink>
        </div>
        <ul class="questions">
            <li v-for="question in questions" class="question" :key="question.id">
                <div class="cat">
                    <div class="pic bg_img"></div>
                    <span v-if="question.categories" v-html="question.categories[0]"></span>
                </div>
                <h3 v-html="question.title"></h3>
                <p v-html="question.question"></p>
                <a :href="question.link" class="show_ans" v-html="get_word('show_answer')"></a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return {
            questions: []
        }
    },
    mounted() {
        const {cat, q} = this.$route.query;
        let data = {limit:300};
        if (cat && cat != '') data.categories = [cat];
        if (q && q != '') data.q = q;
        this.api({ action: 'faq/results', data}, (data)=>{
            this.questions = data.data.items;
        });
    },
    computed:{
        pageTitle(){
            return this.$route.query.q ? this.get_word('showing_results') + ' #' + this.$route.query.q : this.get_word('all_questions');
        }
    }
}
</script>

<style lang="scss">
    #questions_page {
    &{padding-top: 20px;}
	.top_container{
        &{gap: 15px;}
		h2{
            &{display: flex;gap: 15px;}
            &::before{height: 33px;width: 2px;background:var(--gradient1);display: inline-block;content:"";}
        }
		
		.btn.primary{
            &{height: fit-content;white-space: nowrap;line-height: 1;}
		}
	}
	
		ul.questions{
		@media(min-width: 600px){column-count: 5;}
		@media(max-width: 600px){}
		.question{
			&{margin-bottom: 20px;}
			@media(min-width: 600px){}
			@media(max-width: 600px){}
			
			p{
				@media(min-width: 600px){}
				@media(max-width: 600px){}
			}
		}
	}
}
</style>

