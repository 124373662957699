import { createStore } from 'vuex'

export default createStore({
  state: {
    
    articles: [],
    homepage_data: {},
    videos: [],
    faq: [],
    events: [],
    words: {},
    bannersData: [],
    playerMode: ''
    
  },
  bannersUsage: [],
  mutations: {
    SAVE_BANNERS_DATA(state, data) {
      state.bannersData = data;
    }
  },
  actions: {
    saveBannersData({ commit }, data) {
      commit('SAVE_BANNERS_DATA', data);
    }

  },
  modules: {
  },
  getters: {
    get_banner(state){
      return state.bannersData;
    }
     
  }
})
