<template>
    <div  :class="'widget_item ' + mode" v-if="loaded && item">
        
      <div v-if="mode != 'minimized'"  class="writer_wrapper" :style="{'margin-top': is_mobile() ? 'var(--header_height)' : '0'}">
        <router-link :to="'/profile/' + item.lecturer.id">
          <UserIcon :user="itemNew.lecturer" size="sml" />
          <div class="content">
              <span v-html="itemNew.lecturer.display_name"></span>
          </div>
        </router-link>
        <button @click="minimize" class="btn_minimize clean_btn">
          <i class="fas fa-angle-down"></i>
        </button>
      </div>
  
      <div class="media_wrapper">
        <video-player :src="item.vid_embed" :controls="mode != 'minimized'" autoplay="1" :type="item.mime" :poster="item.pic_mid" :volume="0.6" :techOrder="['youtube']" :sources="[{ type: itemNew.mime, src: itemNew.vid_embed}]" :youtube="{ iv_load_policy:1, ytControls: 2 }" class="vjs-16-9" ref="vid">
            <template v-slot="{ player, state }">
              <div v-if="mode === 'minimized'" class="custom-player-controls">
                <button @click="state.playing ? player.pause() : player.play()" :class="{play: state.playing}">
                  <div v-if="state.playing">
                    <i  class="fas fa-pause"></i>
                  </div>
                  
                  <div v-if="!state.playing">
                    <i class="fas fa-play"></i>
                  </div>
                </button>
              </div>
            </template>
        </video-player>

        <div v-if="mode === 'minimized'" class="mini_actions_wrapper">
          <h4 @click="mode = 'maximized'" v-html="item.title"></h4>
          <button class="btn_pause clean_btn">
            <i class="fas fa-pause"></i>
          </button>
          <button @click="close" class="btn_close clean_btn">X</button>

        </div>
      </div>
      <div v-if="mode != 'minimized'" class="bottom_wrapper">
          <div class="actions_wrapper">
              <like :item="item" v-model="itemNew.user_liked" @likechange="blat" />

              <button @click="$bus.$emit('second_page', {type: 'comments', id: item.id, item: item});mode = 'minimized'">
                <i class="far fa-comment"></i>
              </button>
              <button>
                <i class="far fa-paper-plane"></i>
              </button>
              <bookmark :item="item" v-model="itemNew.user_bookmarked" />
          </div>
  
          <span v-if="itemNew.num_likes && itemNew.num_likes > 0" class="like_count" v-text="itemNew.num_likes > 1 ? itemNew.num_likes + ' ' + get_word('likes') : itemNew.num_likes + ' ' + get_word('like')"></span>
  
          <h3 class="vid_title" v-html="itemNew.title"></h3>
          <div class="date" v-html="itemNew.date_parse"></div>
      </div>
        
    </div>
  
  </template>
  
  <script>
  import UserIcon from '@/components/user_icon.vue';
  import Like from '@/components/feed/like.vue';
  import Bookmark from '@/components/bookmark.vue';
  export default {
  components: { UserIcon, Like, Bookmark },
    props: ["m_id", "m_item"],
    data() {
      return {
        mode: 'closed',
        item: false,
        sections: [],
        loaded: false,
        id: this.$route.params.id,
        itemNew: this.m_item,
        playVideo: true,
      };
    },
    mounted() {
      
        this.$bus.$on('minimize_item_page', this.minimize);

        if (this.m_id > 0) this.id = this.m_id;
        if (!this.isNull(this.m_item))
        {
            this.item = this.m_item; 
            this.loaded = true;
            setTimeout(()=>{this.mode = 'maximized'}, 100);
        }
        else
        {
            this.api({ action: 'feed/results', data: {id: this.id} }, (data) => {
                this.item = data.data.items[0]; 
                this.loaded = true;
                this.mode = 'maximized';
            });
        }
      },
    
    methods:{
      minimize(){
        this.mode = 'minimized';
      },
      close(){
        this.mode = 'closed';
        setTimeout(()=>{
          this.$bus.$emit('close_item');
          this.item = false;
          this.itemNew = null;
        }, 100);
      },
      blat() {
        this.itemNew.num_likes = this.itemNew.num_likes*1 + (this.itemNew.user_liked ? 1 : -1);
      }
    },
    watch: {
      m_item(newItem) {
          this.itemNew = newItem;
          this.mode = 'maximized';
      },
      mode(newMode){
        this.$store.state.playerMode = 'player_' + newMode;
      }
    }
      
  }
  
  </script>
  
<style lang="scss" scoped>
    .widget_item {
      &{transition: all .35s ease 0s;position: fixed;left: 0;right: 0;overflow: hidden;}

      &.closed{bottom: 0;transform: translateY(100%);transform-origin: bottom;height: 0;}

      &.maximized{height: calc(100vh - var(--header_height));transform: translateY(0px); bottom: 0;background-color: var(--clr_main2);}

      .writer_wrapper {
          & {display: flex;gap: 10px;padding: 10px;}

          a {
            &{display: flex;gap: 8px;}
            .content {
              span { font-weight: 600; color: var(--clr_main);}
            }
          }

          .btn_minimize{margin-inline-start: auto;font-size: 19px;}
      }

      .media_wrapper{ 
        .post_pic { background-size: cover; background-position: center center; padding-bottom: 54%;}
        video { width: 100%;}
      }

      &.minimized{
        &{bottom: 60px;height: 81px;transform: translateY(0px);}
        .media_wrapper{
          &{background-color: var(--clr_main2);height: 100%;display: flex; gap: 5px;}
          .video-js{padding-top: 20.25%;width: 148px;}

          .mini_actions_wrapper{
            &{display: flex;align-items: center;}
            button{flex-basis: 25%;font-size: 20px;font-weight: bold;}
            .btn_pause{color: var(--clr_main2);}
          }
        }

        .custom-player-controls{
          &{position: absolute;top: 48%;transform: translateY(-50%);right: 81vw;color: var(--clr_main);}
          button{font-size: 19px;}
        }
      }

      .bottom_wrapper {
        &{padding: 10px;}
        .actions_wrapper {
            &{display: flex;gap: 15px;}
            button{ background-color: transparent; border: none;font-size: 22px; }
        }

        .like_count{margin-bottom: 10px;display: block;font-weight: 600;}

        .vid_title{line-height: 1.1;font-size: 21px;}

        .like_count{font-size: 18px;line-height: 1.1;}
      }
    }
  </style>