<template>

    <div id="comments_page">
        <div class="comments">
        
            <div class="writer_wrapper">
                <user-icon size="small" :user="item.lecturer"/>
                <div class="content">
                    <span v-html="item.lecturer.display_name"></span>
                    <p class="desc" v-html="item.title"></p>
                </div>
            </div>
            <div class="bottom_wrapper">
                <span class="like_count" v-text="`${item.num_likes} ${item.num_likes > 1 ? get_word('likes') : get_word('like')}`"></span>
            </div>
        
            <h3 class="mid_ttl" v-html="get_word('comments')"></h3>
                         
            <span class="no_comments" v-if="activeComments.length < 1" v-html="get_word('no_comments')"></span>
      
        
            <div v-for="(comment, index) in activeComments" :key="comment.id" class="comment">
        
                <div class="number" v-text="(comments.length - index) + '.'"></div>
        
                <div class="content">
                    <label  @click="comment.is_open = !comment.is_open">{{comment.title}} <span v-if="comment.message == ''">(ל&quot;ת)</span></label>
                    <div class="msg" v-text="comment.message" v-if="comment.is_open"></div>
                    <div class="foot">
                        <span class="user_name">{{comment.writer}}</span> |
                        <span class="date">{{ comment.date_parsed }}</span>
                    </div>
                </div>
        
            </div>
        
            <a v-if="comments.length > 5 && !show_all" @click="show_all = true" class="btn" v-html="get_word('show_all_comments')"></a>
        
        </div>
        
            <div :class="{open: show_comment_form}" class="form_section_wrapper">
                <div class="top_wrapper">
                    <a class="btn_comment" @click="show_comment_form=true" v-html="get_word('add_comment')"></a>
                    <i class="far fa-comment"></i>
                </div>
        
                <form  @submit.prevent="add_comment();">
                    <ul class="frmUL">
                        <li><input required type="text" name="writer" v-model="data.writer" :placeholder="get_word('writer_name')"></li>
                        <li><input type="email" name="email" v-model="data.email" :placeholder="get_word('email') + ` (${get_word('not_required')})`"></li>
                        <li><input required type="text" name="title" v-model="data.title" :placeholder="get_word('subject')"></li>
                        <li><textarea required name="msg" v-model="data.message" :placeholder="get_word('content')"></textarea></li>
                    </ul>
        
        
        
        
                    <div style="display:flex; justify-content: space-between;align-items:center;">
                        <a class="cancel" @click="show_comment_form=false" v-html="get_word('cancel')"></a>
                        <input type="submit" class="btn primary" :value="get_word('add_comment')">
                    </div>
                    <div class="clr"></div>
                </form>
            </div>
    </div>
    
    </template>
    
    <script>
import userIcon from '@/components/user_icon.vue';
    
    export default {
  components: { userIcon },
        props: ["item_id", "item_type", "item"],
        data(){
            return {
                data: {
                    writer: '',
                    email: '',
                    title: '',
                    message: '',
                    item_id: this.item_id,
                    item_type: this.item_type,
                },
                show_comment_form: false,
                show_all: false,
                comments: []
            }
        },
        methods: {
            add_comment(){ 
    
                var self = this;
    
                this.api({ action: 'comments/add', data: this.data}, ()=>{
                    
                    self.data.writer = '';   
                    self.data.email = '';   
                    self.data.title = '';   
                    self.data.message = '';   
    
                    self.show_comment_form = false;
    
                    self.refresh();
                    this.alert(this.get_word('comment_added'), null, true);
                });
                
            },
            refresh(){
                        
                var self = this;
                // categories
    
                this.api({ action: 'comments/get', data: { id: this.item_id, type: this.item_type }}, function(data){
    
                    for (let i in data.data.comments) data.data.comments[i].is_open = false;
                    
                    self.comments = data.data.comments;
    
                });
                
            }
        },
       computed: {
         activeComments: function() {
    
            if (this.show_all) return this.comments;
            else 
            {
                var arr = [];
                for (let i in this.comments)
                {
                    if (i > 4) break;
                    arr.push(this.comments[i])
                }
                
                return arr;
            }
         }
        },
    
      mounted() { 

          this.refresh();
      },
        
    }
    
    </script>
    
    <style lang="scss" scoped>
    
    /* COMMENTS */

    #comments_page{
        &{display: flex;flex-direction: column;height: 100%;overflow: hidden;position: relative;}
        
        .no_comments{padding: 0 10px;}
        
        .comments { 
            &{padding:20px 0 55px 0;overflow-y: auto;}
            .mid_ttl{padding: 0 10px;}
            .comment { display:block; position:relative; padding: 10px;display: flex;gap: 10px;align-items: center;}
            .comment .remove { width:20px; height:20px; background:#C0C0C0; position:absolute; top:20px; text-align:center; line-height:20px; left:0; }
            .comment .number {  color:var(--color1); font-size:25px; line-height: 1; right:0; }
            .comment label { cursor:pointer; font-size:22px;  display: block; font-family: var(--font); }
            .comment .msg {   font-size:16px; }
            .comment.opened .msg { display:block; white-space: pre-line; }
            .add_comment_wrap { display:block; position:relative; padding:5px 0 0 14px; }
            .add_comment_wrap .btn_comment { display:block; color:#9E9E9E; cursor:auto; width:100%; border:1px solid #b2b2b2; padding:10px; border-radius:3px; margin-bottom:10px; }
            .add_comment_wrap form { display:none; }
            .add_comment_wrap {
                input[type=text], textarea { width:100%; border:1px solid #b2b2b2; padding:10px; border-radius:3px; margin-bottom:10px; }
                textarea { height:100px; }
            }
            
            input[type=text]:focus, .add_comment_wrap textarea:focus { border:1px solid #09a5d9; box-shadow:0 0 7px rgba(0,0,0,0.2);}
            .comments .add_comment_wrap .btn { margin-right:10px; }
            .comment .foot { color:#8e8e8e; font-size:13px; }
            .see_more_comments { width:220px; margin:0 auto; margin-top:10px; display:block; position:relative; font-size:14px; padding-right:20px; }
            .see_more_comments:after { content:""; display:block; width:16px; position:absolute; top:0px; bottom:0px; right:10px;}


        .writer_wrapper {
            & {display: flex;gap: 10px;padding: 10px 0;margin: 0 10px;border-bottom: var(--clr_border) 1px solid;}

            .writer_pic {
                & { border-radius: 100%;height: 32px;width: 32px;background-size: contain;background-repeat: no-repeat;background-position: center; }
            }

            .content { 
            span { font-weight: 600; }
            p,span{font-size: 18px;line-height: 1.1;}
            }
        }

        .bottom_wrapper {
        .actions_wrapper {
            &{padding: 10px;display: flex;gap: 15px;}
            button{ background-color: transparent; border: none;color: #fefefe;font-size: 22px; }
        }

        .like_count{margin-bottom: 10px;display: block;padding: 0 10px;}

        .desc{padding: 0 10px;}
        }
    }

    .form_section_wrapper {
        &{background-color: var(--clr_comments);padding: 15px;bottom: 0;position: absolute;left: 0;right: 0;transform: translateY(calc(100% - 50px));transition: all .3s;}

        &.open{transform: translateY(0px);}

        .top_wrapper {
            &{display: flex;align-items: center;justify-content: space-between;}
            .btn_comment{
                &{color: var(--clr_main);font-weight: 600;font-size: 19px;}
            }

            .fa-comment{font-size: 20px;}

        }

            form {
                ul {
                    li {
                        input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=file]):not(.vue-number-input__input){
                            &{border: none;border-bottom: 1px solid #ccc;font-size: 18px;background-color: transparent;border-radius: 0;padding: 0;box-shadow: none;color: var(--clr_main);}
                        }
                        textarea{border: 1px solid #ccc;border-radius: 8px;padding: 10px;background: transparent;color: var(--clr_main);}
                    }
                }

                .btn{background-color: var(--color1);color: #fefefe;}

            }

                .cancel{color: var(--clr_main);}

    }


    }  
    </style>