import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'
import NotFound from '../views/NotFound.vue'
import about from '../views/about.vue'
import contact from '../views/contact.vue'
import item from '../views/item.vue'
import profile from '../views/profile.vue'
import donation from '../views/donation.vue'
import settings from '../views/settings.vue'
import search from '../views/search.vue'
import hevruta from '../views/hevruta.vue'
import question from '../views/faq/question.vue'
import faq from '../views/faq/index.vue'
import ask from '../views/faq/ask.vue'
import allQuestions from '../views/faq/questions.vue'
import successPage from '../views/successPage.vue'
import login from '../views/login.vue'
import tag from '../views/tag.vue'
import magazine from '../views/magazine.vue'
import bookmarks from '../views/bookmarks.vue'

const routes = [
  //{ path: '', name: 'Home', component: Home, props: { footer: true, header: true, footerArticles: false, hide_back: true} },
  { path: '/', name: 'Home', component: Home, props: { footer: true, header: true, footerArticles: false, hide_back: true} },
  { path: '/index.html', redirect: '/' },
  //{ path: '/article/:id', component: articlePAge, props: { footer: true, header: true, footerArticles: true } },

  { path: '/magazine', component: magazine, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/about', component: about, props: { footer: true, header: true, footerArticles: false} },

  { path: '/bookmarks', component: bookmarks, props: { footer: true, header: true, footerArticles: false} },

  { path: '/success_page', component: successPage, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/donation', component: donation, props: { footer: true, header: true, footerArticles: false} },

  { path: '/settings', component: settings, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/hevruta', component: hevruta, props: { footer: true, header: true, footerArticles: false} },

  { path: '/search', component: search, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/faq', component: faq, props: { footer: true, header: true, footerArticles: false} },

  { path: '/faq/ask', component: ask, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/faq/:id', component: faq, props: { footer: true, header: true, footerArticles: false} },

  { path: '/question/:id', component: question, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/faq/questions', component: allQuestions, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/contact', component: contact, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/login', component: login, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/item/:id', component: item, props: { footer: true, header: true, footerArticles: false} },
  
  { path: '/profile/:id', component: profile, props: { footer: true, header: true, footerArticles: false} },

  { path: '/tag/:id', component: tag, props: { footer: true, header: true, footerArticles: false} },

  { path: "/:catchAll(.*)", name:'404', component: NotFound, props: { footer: true, header: true, footerArticles: true } },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  
  scrollBehavior(/*to, from, savedPosition*/) {
    return new Promise((resolve/*, reject*/) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 200)
    })
  },
})


export default router
