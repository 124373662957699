<template>
    
<div id="vod_index" v-if="loaded">
    <div id="vod_hero">
        <div class="hero_content">
            <a class="bg_pic" @click="$bus.$emit('open_item', activeItem)" :style="'background-image:url(' + activeItem.pic_tumb + ')'"></a>
            <div class="vod_centered texts_wrapper">
                <div class="texts">
                    <a @click="$bus.$emit('open_item', activeItem)"><h1 v-html="activeItem.title"></h1></a>
<!--                    <h2 v-html="activeItem.short_text"></h2>-->
                    <div class="vid_info">
                        <span v-text="activeItem.date_parse"></span>
                        <span><i class="far fa-clock"></i>{{activeItem.duration_parse}}</span>
                    </div>
                    <!-- <a class="btn_hero" :href="activeItem.link"></a> -->
                </div>
            </div>
        </div>
    </div>

    <div id="vod_magazine" class="paddme">
        
        <div class="tags gap_bottom">
            <a @click="$bus.$emit('second_page', {type:'category' , category: subject})" v-for="subject in subjects" :key="subject.id" v-text="subject.name"></a>
        </div>

        <section v-for="section in sections" :key="section.id" class="vod_section">
            <div v-if="section.name != ''" style="cursor: pointer" class="vod_section_title" @click="filterByItem({ type: 'category', id: section.id });window.scrollTo(0,0)">
                <h3 v-html="section.name"></h3>
                <button class="clean_btn" @click="$bus.$emit('second_page', {type:'category' , category: section})" v-html="get_word('show_all')"></button>
            </div>
            <carousel :items-to-show="2.2" dir="rtl">
            
                <slide v-for="item in section.items" :key="item.id">
                    <article class="article_block no_text_transition">
                        <a @click="$bus.$emit('open_item', item)" class="article_pic radius">
                            <span :style="bg(item.pic_tumb)"></span>
                        </a>

                        <div class="content">
                            <router-link :to="'/profile/' + item.lecturer.id" class="lecturer" :style="bg(item.lecturer.pic_tumb_sml)" ></router-link>

                            <router-link v-if="item.lecturer.display_name != ''" :to="'/profile/' + item.lecturer.id">
                                <div class="lecturer_name" v-text="item.lecturer.display_name"></div>
                            </router-link>

                            <a :href="item.link">
                                <h3 v-text="item.title"></h3>
                            </a>
                        </div>
                        
                        <div class="duration_text" v-text="item.duration_text"></div>

                        <div class="soci">
                            <div class="like"><i class="far fa-heart"></i><span v-text="item.num_likes"></span></div>
                            <div class="views"><i class="far fa-eye"></i><span v-text="item.total_views"></span></div>
                        </div>
                    </article>
                </slide>
            </carousel>
             
        </section>
    </div>

</div>
<div class="loading" v-else><div class="lds-dual-ring"></div></div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
  },
    data() {
        return {
            loaded: false,
            hero: [],
            sections: [],
            subjects: [],
        };
    },
    mounted() {
       
        this.api({ action: "vod/home", data: { user_id: this.get_me().id } }, (data) => {
            this.hero = data.data.hero;
            this.sections = data.data.sections;
            this.subjects = data.data.subjects;
            this.loaded = true;
            
            setInterval(this.next, 10*1000);
        });
        
    },
    computed: {
        activeItem: function()
        {
            if (this.hero.length == 0) return {};
            
            for (let i in this.hero)
            {
                if (this.hero[i].active) return this.hero[i];
            }

            return this.hero[0];
        }
    },
    methods: {
        
        setActive(item){
            for (let i in this.hero)
            {
                this.hero[i].active = (this.hero[i].id == item.id);
            }
        },
        next(){
            let next = false;
            
            for (let i in this.hero)
            {
                if (next)
                {
                    this.setActive(this.hero[i]);
                    return;
                }
                if (this.hero[i].id == this.activeItem.id)
                {
                    next = true;
                }
            }
            
            this.setActive(this.hero[0]);
        }
    }
}

</script>

<style lang="scss" scoped>
#vod_index {
        .carousel__slide{min-width: 200px;}

    #vod_hero {    
        #left_icon { position: absolute;  z-index: 9;
            @media (min-width: 600px) { width:115px; height:174px; inset-inline-end: 0px; top: 80px; }
            @media (max-width: 600px) { display: none; }
        }
        
        .hero_content {  background-size: cover; position: relative; 
            
            .personal_site & { width: auto; margin-bottom: var(--gap); 
                @media (min-width: 600px) { margin: 0 80px;  }
                @media (max-width: 600px) { }

            }
            
            .btn_hero{ position: relative; display: flex; align-items: center; gap: 15px; 
                    @media (max-width: 600px) {z-index: 9;position: absolute;top: -50px; }
                .main_site & { color: var(--mainTextColor); }
                .personal_site & { color: #fff; }
            }
            .btn_hero::before{display: block; /*background-image: url(../images/play.svg);*/background-position: center;background-size: contain;background-repeat: no-repeat;height: 50px;width: 50px;content:'';}
            .bg_pic { display: block; background-size: cover; background-position: center; /*@include transition(all 0.3s ease 0s);*/
            
                width: 100%;
                padding-bottom: 64%;
                position: relative;
            }


            .main_site & {
                @media (min-width: 600px) { 
                    & { min-height: 34vw; }
                    &::after { content: ""; position: absolute; pointer-events: none; inset-inline-start: 0px; top: 0px; bottom: 0px; width: 80%; z-index: 1; background-image: linear-gradient(to left, #0e1128 0%, #0e1128 40%, rgba(8, 20, 33, 0) 100%); }
                    &::before { content: ""; position: absolute; pointer-events: none; right: 0px; left: 0px;  bottom: 0px; width: 100%; height: 5%;  z-index: 1; background-image: linear-gradient(to top, #0e1128 0%, #0e1128 40%, rgba(8, 20, 33, 0) 100%); }
                    .bg_pic { position: absolute; inset-inline-end: 0px; top: 0px; bottom: 0px; width: 75%; }
                    
                    .texts_wrapper { width: 60%;}
                }
                @media (max-width: 600px) { 
                    & {  margin: 0 -10px; margin-bottom: 30px;}

                    .bg_pic { width: 100%; padding-bottom: 64%; position: relative; 
                        &::after { content: ""; position: absolute; right: 0px; left: 0px; bottom: 0px; height: 80%;  background-image: linear-gradient(to top, #0e1128 0%, #0e1128 0%, rgba(8, 20, 33, 0) 100%); z-index: 1;}
                    }

                }
            }
            
            .texts { display: flex;  flex-direction: column; gap:var(--gap);
                @media (min-width: 600px) { min-height: 60vh; position: relative; z-index: 2;justify-content: center; align-items: flex-start;  }
                @media (max-width: 600px) { padding: var(--gap) 20px;position: relative;
                    #breadcrumbs { margin: 0px;}
                }  
            }
            h1 {font-weight: bold; line-height: 0.83; letter-spacing: 1.2px; color: var(--clr_main);display: -webkit-box;-webkit-line-clamp: 9;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;
                @media (min-width: 600px) { font-size: 60px; 
                    .main_site & { width: 40%; }
                }
                @media (max-width: 600px) { font-size: 30px; }  
            }
            h2 {  font-weight: normal;  letter-spacing: 0.44px;  
                @media (min-width: 600px) { font-size: 22px; width: 35%; line-height: 0.91; }
                @media (max-width: 600px) { font-size: 16px; line-height: 1.2; } 
            }
            
            .vid_info { color: var(--color_link); font-size: var(--smlFontSize); display: none;
                    @media (max-width: 600px) { display: none} 
                &::before { content: ""; display: block; background: linear-gradient(81.62deg, #3B5EC2 -17.79%, #7FFFC0 65.8%, #F9F871 141.11%); height: 1px; width: 80px;
                    @media (min-width: 600px) { margin: 15px 0; }
                    @media (max-width: 600px) { margin: 5px 0; } 

                }
                span { display: inline-block; vertical-align: middle;
                    i { font-size: 15px; margin-inline-end: 5px; }
                    &::after { content: "|"; display: inline-block; color:var(--color_link); margin: 0 8px; font-size: 18px; vertical-align: middle; }
                    &:not(:first-child)::after { display: none; }
                }
            }
            
            .personal_site & { display: flex;   overflow: hidden; margin-bottom: var(--gap);
                @media (min-width: 600px) { flex-direction: row-reverse; border-radius: var(--radius); }
                @media (max-width: 600px) { flex-direction: column; } 

                .texts_wrapper { background: #33385A; color: #fff; padding: 25px;  position: relative; flex-basis: 30%; 
                    @media (min-width: 600px) {
                        &::after { content: ""; display: block; width:2px; height: 100%; position: absolute; left: 0px; top: 0px; background-image: linear-gradient(81.62deg, #3B5EC2 -17.79%, #7FFFC0 65.8%, #F9F871 141.11%); }
                    }

                }
                .bg_pic {
                    @media (min-width: 600px) { flex-basis: 70%; }
                    @media (max-width: 600px) { height: 250px; } 
                }
            }
            
        }
    }
    
    .vod_section{
        &{margin-bottom: 15px;padding-bottom: 10px;}
        &:not(:last-child){border-bottom: 1px solid var(--clr_border);}
        .vod_section_title{margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;}
    }

    .article_block {
        .content {
            h3{-webkit-line-clamp: 3;}
        }
    }
}

</style>