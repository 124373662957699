<template>
  <div id="latest_questions">
	<div class="top_container">
		<h2> {{get_word('latest_questions')}}   {{ category && category.name != '' ? get_word('subject') + ' ' + category.name : ''}}</h2>
		<button @click="$bus.$emit('second_page', {type: 'all_questions'})" class="btn" v-html="get_word('see_all_questions')"></button>
	</div>
	
	<ul class="questions" v-if="latestQuestions && latestQuestions.length">
		<li v-for="question in latestQuestions" :key="question.id" class="question">
			<div class="cat">
				<div class="pic bg_img"></div>
			<span v-if="question.category.name != ''" v-html="question.category.name"></span>
			</div>
			<h3 v-html="question.title"></h3>
			<p v-html="question.question"></p>
			<a :href="'/question/' + question.id" class="show_ans" v-html="get_word('show_answer')"></a>
		</li>
	</ul>
	<h4 class="no_questions_found" v-else v-html="get_word('no_questions_found')"></h4>

</div>
</template>

<script>
export default {
    props:{
        category: {
            required: false,
            type: Object
        },

        latestQuestions: {
            required: true,
            type: Array
        },
    }
}
</script>

<style lang="scss">
#latest_questions {
	h2{color: var(--clr_main);}
	.ask{
		span{font-size: 18px;color: var(--clr_main);}
	}

}
</style>