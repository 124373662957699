<template>
  <div class="main_wrapper">
	<section class="faq_page centered">
		<div class="faq_hero">
			<div class="top_container">
				<div class="logo bg_img"></div>
				<h1 v-html="get_word('faq_pool')"></h1>
				<!-- <h3 v-html="get_word('faq_hero_subtitle')"></h3> -->
			</div>
			
		<div class="faq_search">
			<h3 class="title_1" v-html="get_word('faq_search_title_1')"></h3>
			<h3 class="title_2" v-html="get_word('faq_search_title_2')"></h3>
			<form @submit.prevent="handleSearch" id="search_form">
				<div class="form_group">
					<input v-model="q" required :placeholder="get_word('free_text')" class="search" type="text" name="q">
					<div class="icon"></div>
				</div>
				<!-- <button class="btn" v-html="get_word('search_answer')"></button> -->
			</form>

			<div class="ask">
				<h3 v-html="get_word('personal_question')"></h3>
				<button class="btn primary" @click="$bus.$emit('second_page', {type: 'ask'})" v-html="get_word('feel_free_to_ask')"></button>
			</div>
				
		</div>
			
		</div>
		
		<select @change="handleCatChange" class="cats">
					<option value="0" v-html="get_word('choose_category')"></option>
					<option v-for="cat in cats" class="cat main_link" :selected="cat.id == selectedCatId" :class=" {has_subs: (cat.subs && cat.subs.length > 0)}" :key="cat.id" :value="cat.id">
                        {{cat.name}}					
					</option>
		</select>
		

		
		<latestQuestions v-if="latestQuestions && latestQuestions.length" :latestQuestions="latestQuestions" :category="selectedCat"/>
		<h2 v-else class="no_questions" v-html="get_word('no_questions_found') + ' ' + selectedCat?.name"></h2>
	</section>
</div>
</template>

<script>
import latestQuestions from '@/components/faq/latestQuestions.vue';
export default {
  components: { latestQuestions },
data(){
    return {
        cats: [],
        latestQuestions: [],
        selectedCatId: 0,
		q: ''
    } 
},
methods: {
	handleCatChange({target:{value}}){
		let data = {limit:10};
		if (value > 0) data.category = value;
			this.api({ action: 'faq/results', data}, (data)=>{
				this.latestQuestions = data.data.items;
				this.selectedCatId = +value;
			});
		
	},
	handleSearch(ev) {
		ev.preventDefault();
		this.$router.push('/faq/questions?q=' + this.q);
	}
},

computed:{
	selectedCat(){
		const cat =  this.cats.find(cat=>cat.id == this.selectedCatId);
		return cat;
	}
},
mounted() {
    this.api({ action: 'faq/categories', method: 'get'}, (data)=>{
        this.cats = data.data;
        // this.selectedCatId = +this.$route.params.id || data.data[0].id;
    });
    this.api({ action: 'faq/results', data:{limit: 10}}, (data)=>{
        this.latestQuestions = data.data.items;
    });
}

}
</script>

<style lang="scss">
.main_wrapper{ background: var(--color);
    --mainTextColor: #fff; color: #fff; 
	&{position: relative;}
	@media(min-width: 600px){padding: 50px 100px;}
    @media(max-width: 600px){}
	&::after{
		&{background:linear-gradient(#fff0 0%, #fff0 30%, #0E1128 100%), url('@/assets/images/faq/hero-bg.png');position: absolute;inset: 0;content: '';z-index: 0;background-repeat: no-repeat;}
		
		@media(min-width: 600px){opacity: 0.4;background-size: 100% 750px;}
		@media(max-width: 600px){opacity: 0.6;background-size: 100% 160px;}
	}
	
	.input_hollow{font-size: 17px;}

	.faq_page {
		&{position: relative;z-index: 1;padding-bottom: 50px;}
		.faq_hero{
			&{margin-bottom: 30px;}
			.btn_primary{font-size: 19px;}
			.top_container{
				&{margin: 0 auto;width: fit-content;display: flex;align-items: center;flex-direction: column;margin-bottom: 60px;
                    @media(max-width: 600px){margin-bottom: 20px;}
                }
				.logo{background-image: url('@/assets/images/faq/hero-logo.png');height: 100px;width: 100px;}
				h1{
					&{line-height: 1.2;}
					@media(min-width: 600px){font-size: 50px;}
					@media(max-width: 600px){font-size: 30px;}
				}
				
				h3 {
					@media(min-width: 600px){}
					@media(max-width: 600px){color: var(--clr_main); text-align: center;}
				}
			}

			.faq_search{
				&{text-align: center;padding: 10px;padding-top: 50px;}
				&>h3{
					@media(min-width: 600px){display: inline-block;margin-bottom: 20px;}
					@media(max-width: 600px){}
					
					&.title_1{
						@media(min-width: 600px){}
						@media(max-width: 600px){line-height: 1;color: var(--clr_main);}
					}
					
					&.title_2{
						@media(min-width: 600px){}
						@media(max-width: 600px){font-size: 16px;margin-bottom: 10px;color: var(--clr_main)}
					}
				}
				form{
					&{display: flex;gap: 10px;margin-bottom: 30px;}
					@media(min-width: 600px){justify-content: center;height: 40px;}
					@media(max-width: 600px){flex-direction: column;}
					
					input{border-radius: 5px;padding: 0 1em;height: 100%;width: 100%;}
					
					.form_group {
						&{position: relative;height: 100%;}	
						&:first-of-type{
							@media(min-width: 600px){flex-basis: 50%;}
							@media(max-width: 600px){}
						}
						
						@media(min-width: 600px){}
						@media(max-width: 600px){height: 40px;}
						
						.icon{position:absolute;left:10px;height: 24px;width: 24px;background-size: contain;background-repeat: no-repeat;top: 50%;transform: translateY(-50%);}
						.search{
							&~.icon{background-image: url('@/assets/images/faq/magnifying-glass.png');}
						}
						.subject{
							&~.icon{height: 16px;background-image: url('@/assets/images/faq/three-dots.png');}
						}
					
					}
					button{background: var(--gradient1);}
				}
				
				.ask {
					&{display: flex;color: var(--clr_main);flex-direction: column;gap: 10px;}
				}
			}
		}
		
		.cats{
            &{width: 100%;height: 40px;margin-bottom: 15px;border-radius: 10px;background-color: var(--clr_main2);color: var(--clr_main);font-size: 17px;font-weight: 600;}
            // option{
            //     &{background-color: var(--clr_link);color: var(--clr_main);}
            // }
						
		}
		

				
	}
}

#questions_page, #latest_questions {
	&{display: flex;flex-direction: column;gap: 30px;position: relative;}
			.top_container{
				&{display: flex;justify-content: space-between;}
				h4, h2{
					&{display: flex;gap: 10px;color: var(--clr_main);}
					&::before{content: "";display: inline-block;width: 2px;height: 100%;background: var(--gradient1);}
				}
				.btn_primary{
					&{padding: 8px 2em;transition: .2s all;}
					&:hover{color: var(--color);}
				}
			}
			
			ul.questions {
				&{column-gap: 15px;}
				.question{
					&{flex-basis: 19%;background-color: var(--clr_secondary);border-radius: 10px;position: relative;break-inside: avoid;padding-bottom: 20px;}
					&>*:not(.cat){padding: 0 20px;}
					.cat {
						&{display: flex;gap:5px;padding: 0 8px;background-color: var(--clr_link);border-radius: 10px 0;margin-inline-start:auto;margin-bottom: 10px;width: fit-content;}
						span{font-size: 17px;color: #fefefe;}
						.pic{width: 16px;background-image: url(https://kol-hatora.newdev.wizzo.co.il/Graphics/Modules/faq/categories/5031_tumb_200X200.png);/*temporary pic for styling*/}
					}
					h3{
						&{line-height: 1.1;}
						&::after{content: "";display: block;width: 40px;height: 2px;background: var(--gradient1);margin: 10px 0;}
					}
					p{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;line-clamp: 3; -webkit-box-orient: vertical;font-size: 17px;line-height: 1.3;}
					.show_ans{
						&{color: var(--color_link);font-weight: 600;}
						&::after{content: "←";margin: 0 5px;}
					}
				}
			}
}

// mobile style
#latest_questions{
	@media(min-width: 600px){}
	
	.top_container{
		.btn_primary{
			@media(min-width: 600px){}
			@media(max-width: 600px){position: absolute;bottom: -18px;transform: translateY(100%);left: 0;right: 0;text-align: center;}
		}
	}
	ul.questions{
		&{color: var(--clr_main);}
		@media(min-width: 600px){column-count: 5;}
		@media(max-width: 600px){display: flex;gap: 20px;overflow: auto;}
		
		.question{
			&{padding-bottom: 20px;}
			@media(min-width: 600px){margin-bottom: 20px;}
			@media(max-width: 600px){min-width: 100%;}
			
			p{
				@media(min-width: 600px){}
				@media(max-width: 600px){}
			}
		}
	}
}





.ltr{
	.main_wrapper .faq_page #faq_form form .btn_primary::after{content: "➜";}
}
</style>