<template>
    <div class="banner_wrapper" v-html="banner_html()">
        
    </div>
</template>

<script>

export default {
    props: ["type"],
  data() {
    return {
        
    };
  },
  methods: {
    banner_html () {
        let data = this.$store.getters.get_banner;

        if (!this.isNull(data[this.type]))
        {
            let items = data[this.type].banners;
            
            let itemsNewList = [];



            for (let i in items)
            {
                itemsNewList[i] = { item: items[i], count: this.$store.bannersUsage[items[i].id] };

                if (this.$store.bannersLastBanner[this.type] == items[i].id) itemsNewList[i].count++;
            }

            // Sort by count
            itemsNewList.sort( this.compare );

            // Get the minimal count group
            let minCount = itemsNewList[0].count;
            for (let i in itemsNewList)
            {
                if (itemsNewList[i].count > minCount) itemsNewList.splice(i, 1);
            }

           // console.log(itemsNewList)

            let key = Math.floor(Math.random()*itemsNewList.length);
            let item = itemsNewList[key].item;
            if (!this.isNull(item)) 
            {
                let bannerID = item.id;

                this.$store.bannersUsage[bannerID]++;
                this.$store.bannersLastBanner[this.type] = bannerID;

                return item.html;
            }

        }

        return '';
    },
    compare( a, b ) {
        if ( a.count < b.count ){
            return -1;
        }
        if ( a.count > b.count ){
            return 1;
        }
        return 0;
    }
  }

}
</script>