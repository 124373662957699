<template>
  <div id="alphabet_ruler">
    <!-- <ul id="ruler">
        <li v-for="char in selectedAlphabet" :key="char">
            <span v-text="char"></span>
        </li>
    </ul> -->
    <div class="items_list">
        <SearchItem v-for="item in items" :item="item" :key="item.id"/>
    </div>
  </div>
</template>

<script>
import SearchItem from '@/components/search_item.vue';
export default {
  components: { SearchItem },
  props: {
    items: {
        type: Array
    }
  },
    data() {
        return {
            alphabets: [
                {id: 1, alphabet:['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת']},
                {id: 4, alphabet:['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']},
                {id: 5, alphabet:['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']},
            ]
        }
    }, 
    computed:{
        selectedAlphabet(){
            const selectedAlphabetParent = this.alphabets.find(alp=>alp.id === +this.platformId());
            return selectedAlphabetParent.alphabet;
        }
    }
}
</script>

<style lang="scss">
    #alphabet_ruler {
        &{position: relative;}
        #ruler{
            &{position: absolute;inset-inline-end: 0;top: 0;bottom: 0;color: var(--color1);font-weight: bold;display: flex;flex-direction: column;align-items: center;gap: 5px;z-index: 10;}
            li{}
        }

        .items_list{
            &{display: flex;flex-direction: column;gap: 10px;position: sticky;}
        }
    }
</style>